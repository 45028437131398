.modalContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 12px;
}

.buttonContainer {
	display: flex;
	justify-content: end;
}

.timeInput {
	display: flex;
	align-items: end;
	gap: 8px;

	& > *:first-child {
		flex: 1 1 90%;
	}

	& > *:last-child {
		flex: 1 1 auto;
	}
}

.passForm {
	display: flex;
	flex-direction: column;
	gap: 24px;
	overflow-y: auto;
	padding-right: 4px;

	// скролл
	&::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}
