.navBar {
  width: 100%;
  padding: 4px 8px;
  max-width: 360px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  button {
    background-color: #fffbff;
  }
}
