.mainCardBlock {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 2px 4px;
	cursor: pointer;
	border-radius: 16px;
	position: relative;
	width: min-content;
	// min-width: calc(33% - 4px);
	min-width: 100%;
	transition: 0.2s ease-in-out;

	&:hover {
		border: 2px solid #ff8e16 !important;

		.mainCardBlock_leftLine {
			background: #ff8e16 !important;
		}
	}

	@media (min-width: 1279.9px) {
		width: min-content;
		min-width: calc(33% - 4px);
	}
}

.active_mainCardBlock {
	border: 2px solid #ff8e16 !important;
}

.noActive_mainCardBlock {
	border: 2px solid #9fc4ff !important;
}

.mainCardBlock_leftLine {
	position: absolute;
	left: -1px;
	width: 4px;
	height: 44px;
	transition: 0.2s ease-in-out;
	content: '';
	border-radius: 0px 16px 16px 0px;
}

.activeMainCardBlock_leftLine {
	background: #ff8e16 !important;
}

.noActiveMainCardBlock_leftLine {
	background: #9fc4ff !important;
}

.mainCardBlock_IconInfo {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
}

.mainCardBlock_nameIcon {
	display: flex;
	min-width: 56px;
	height: 56px;
	padding: 8.6px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	// background: #7B2948;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.44px;
}

.mainCardBlock_info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	> p:first-child {
		color: #000;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
	> p:last-child {
		color: #000;
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.08px;
	}
}

.show {
	width: 100%;
	border: 2px solid #ff8e16;
	transition: 0.2s ease-in-out;
	// &::before {
	// 	border-color: #ff8e16 !important;
	// }
}
