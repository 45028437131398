.header {
	background: #ffffff;
	padding: 8px 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-height: 52px;
}

.header__title {
	color: #000000;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;

	@media (max-width: 419.9px) {
		font-size: 18px;
		line-height: 20px;
	}
}

.header__step {
	color: #000000;
	font-size: 14px;
	line-height: 20px;
}
