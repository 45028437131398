.additionalInfo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    > div:first-child p {
        display: flex;
        flex-direction: column;
        gap: 4px;
        color: #000;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.108px;
    }
}