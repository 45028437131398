.bgModal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10002;
  position: fixed;
  width: 100vw !important;
  height: 100svh;
  background: rgba(30, 30, 30, 0.56);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  overflow-y: hidden;
}
  
.bg {
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: #FFFBFF;
  display: flex;
  padding: 16px;
  flex-direction: column;
  max-width: 392px;
  gap: 16px;
  p {
    color: #1C1C1E;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    @media(max-width: 640px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.modalBtns {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  button {
    width: 100% !important;
  }
}