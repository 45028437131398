.account__wrap {
	width: 100%;
	max-width: 100%;
}

.account__container {
	background: #101a28;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: calc(100% - 107px);

	@media (min-width: 639.9px) {
		padding: 16px;
	}

	@media (min-width: 1279.9px) {
		height: calc(100% - 52px);
	}
}

.account__stack {
	height: 100%;
	padding: 8px;
	border-radius: 24px;
	background: rgba(255, 251, 255, 0.92);
	box-shadow:
		0px 8px 24px -4px rgba(24, 39, 75, 0.08),
		0px 6px 12px -6px rgba(24, 39, 75, 0.12);

	@media (min-width: 639.9px) {
		padding: 16px;
	}
}
