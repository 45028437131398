.card {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 12px;
	background-color: #fff;
	border-radius: 12px;
}

.cardHeader {
	display: flex;
	flex-direction: column;
	gap: 4px;
	& > span:first-child {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
	& > span:last-child {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0.08px;
	}
}

.description {
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.08px;
}

.stepContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: #f3eeff;
	height: 100%;
	border-radius: 16px;
	padding: 12px 8px 20px 8px;
}

.title {
	color: #000;
	font-family: Montserrat;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.cardContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-y: auto;
}

.actionButton {
	margin-top: auto;
	width: 100%;
	& button {
		width: 100%;
	}
}
