.layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100svh;
}

.layout__aside {
  width: auto;
}
