.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.content {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// padding-bottom: 20px;
}

.nav_cross {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.title {
	color: #1c1c1e;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 8px;
}

.clientData,
.clientContacts {
	border: 1px solid black;
	border-radius: 8px;
	padding: 8px;

	p:not(:first-of-type) {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}
}

.actionContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	& button {
		width: 'auto';
	}
}
