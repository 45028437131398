.main_block_damageList {
    display: flex;
    flex-direction: column;
}

.add_damage_btn {
    position: absolute;
    right: 12px;
    top: 12px;
}

.damage_list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}