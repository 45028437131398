.containers__search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.main_block {
    border-radius: 16px;
    background: #fffbff;
    padding: 12px;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 12px;
}

.main_block_title {
    color: #1C1C1E;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
}

.main_block_topLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
}