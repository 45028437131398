.modal__card {
	width: 100%;
	// max-height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// height: -webkit-fill-available;
	height: calc(100% - 24px);
	// gap: 24px;

	@media (min-width: 1280px) {
		height: 100%;
		max-height: calc(100vh - 24px);
	}
}

.modal__card1 {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	height: inherit;
	max-height: calc(100vh - 136px);
}

.modal__card2 {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	height: -webkit-fill-available;

	@media (min-width: 1280px) {
		justify-content: space-between;
	}
}

.modal__card3 {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	height: inherit;
	max-height: calc(60vh - 66px);

	@media (min-width: 1280px) {
		max-height: calc(100vh - 206px);
	}
}

.modal__card_p {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.card__user {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	/* overflow-y: scroll; */
	/* height: 70%; */
	// height: fit-content;

	> p {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
	}
}

.MainCardInfo {
	display: flex;
	gap: 12px;
	flex-direction: column;
	height: 100%;
	// overflow: auto;
	// height: 100%;
}

.card__img {
	border-radius: 12px;
	min-width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #ffffff;
}

.card__info {
	color: #000;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 6px;

	span {
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
	}

	p {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}
}

.card__btns {
	display: flex;
	justify-content: space-between;

	@media (max-width: 435.9px) {
		gap: 12px;
		align-items: center;
	}
}

.card__btns > button:first-child:last-child {
	margin-left: auto;
}

.card__btns_stack {
	position: absolute;
	bottom: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: calc(100% - 32px);

	> div {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}
	button {
		width: 100%;
	}
}

.block__list {
	overflow-y: scroll;
	position: relative;
	gap: 4px;
	display: flex;
	flex-direction: column;
	height: 73svh;
	// скролл
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}
.block__list_header {
	// position: absolute;
	// top: 0;
	// left: 0;
	// right: 0;
	// height: 30px;
	color: white;
	display: flex;
	padding: 12px 8px;

	align-items: center;
	gap: 40px;
	align-self: stretch;
	border-radius: 16px 0px 0px 0px;
	background: var(--core-secondary-30, #3c4858);

	position: sticky;
	top: 0;
	z-index: 10000;
	max-height: 60px !important;
}
.titleRool {
	color: #000 !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 24px !important;
	background-color: transparent !important;
}

.block__list li {
	gap: 16px;
	display: flex;
	align-items: center;
	padding: 8px;
	justify-content: space-between;
	color: #2c2c2e;
	font-family: 'AvenirNextCyr';
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	transition: 0.2s;
	cursor: pointer;
	background-color: #f3eeff;
	border-radius: 8px;
	color: #5c53a7;
}

.selected {
	background: #f3eeff;
}

.card__userName {
	display: flex;
	align-items: center;
	gap: 8px;
}

.card_change_btns {
	justify-content: space-between;
	// margin-bottom: 12px;
	display: none;
}

.card_changeTrue_btns {
	display: flex !important;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
	// margin-bottom: 12px;

	button {
		width: 100%;
		max-width: 100px;
	}
}

.card__userInfo {
	overflow: auto;
	width: 100%;
	padding-right: 8px;
}

@media (max-width: 1279.9px) {
	.card__userInfo {
		min-height: calc(60vh - 140px);
		max-height: calc(60vh - 140px);
	}

	.MainCardInfo > *:nth-child(2):last-child {
		min-height: calc(60vh - 130px);
		max-height: calc(60vh - 130px);
	}
}

.card__userSelect {
	position: relative;
}

.card__selectModal {
	z-index: 1;
}

.card__userInfo::-webkit-scrollbar {
	width: 2px;
}

.card__userInfo::-webkit-scrollbar-track {
	background-color: #e4dfff;
	border-radius: 4px;
}

.card__userInfo::-webkit-scrollbar-thumb {
	background-color: #8f86de;
	border-radius: 4px;
}

.card__userInfo > form {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.card__empls {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.card__userInfo > form div {
	margin-top: 0 !important;
}
.card__chooseModalsBtns {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.terminal__accessList {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.terminal__accessCard {
	padding: 10px 12px;
	border-radius: 16px;
	border: 2px solid #149527;
	display: flex;
	justify-content: space-between;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		height: 42px;
		top: 50%;
		left: -1px;
		border: 2px solid;
		border-color: #149527;
		transition: 0.2s ease-in-out;
		border-radius: 0px 8px 8px 0px;
		transform: translateY(-50%);
	}
}

.terminal__accessCard_info {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.terminal__accessCard_name {
	color: #000;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.08px;
}

.terminal__accessCard_address {
	color: #000;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.08px;
}

.terminal__accessCard_stack {
	display: flex;
	flex-direction: column;
	gap: 9px;
	align-items: flex-end;
}

.terminal__accessCard_access {
	color: #149527;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.08px;
}

.terminal__accessCard.ban {
	border: 2px solid var(--core-primary-80, #9fc4ff);

	&::before {
		border-color: #9fc4ff;
	}

	.terminal__accessCard_access {
		color: #9fc4ff;
	}
}

.modal__preloader {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal__userInfo {
	height: 100%;
}

.wrapper_contentUsers {
	height: 100%;
}
.chooseButtons_block {
	display: flex;
	gap: 8px;
}

.permission_checkbox_list {
	display: flex;
	flex-direction: row;
	width: 480px;
	// gap: 95px;
	gap: 113px;
	justify-content: center;
	& li {
		background-color: white;
	}
}

.block {
	border-radius: 12px;
	background: #fffbff;
	padding: 16px;
	gap: 20px;
	width: 100%;
	max-width: 360px;
	display: flex;
	flex-direction: column;
}

.block h5 {
	color: #000;
	font-family: 'AvenirNextCyr';
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0.24px;
	text-align: center;
}

.block__choice {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.block__btns {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 16px;
}

.block__descr p {
	color: #000;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.06px;
	margin-top: 8px;
}

.block__inps {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.block__info {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 24px;
}

.block__info p {
	color: #2d2276;
	font-family: 'AvenirNextCyr';
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0.035px;
}

.block__list_1 {
	//   gap: 4px;
	display: flex;
	flex-direction: column;
	//   height: 390px;
	//TODO некорректно
	// height: 43vh;
	// max-height: calc(100% - 730px);
	overflow-y: auto;
	position: relative;
	gap: 4px;
	display: flex;
	flex-direction: column;
	//   height: 73svh;
	// скролл
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}

.block__list li {
	gap: 16px;
	display: flex;
	align-items: center;
	padding: 8px;
	justify-content: space-between;
	color: #2c2c2e;
	font-family: 'AvenirNextCyr';
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	@media (min-width: 768px) {
		&:hover {
			cursor: pointer;
			background: #f3eeff !important;
			border-radius: 8px;
			color: #5c53a7;
		}
	}
}

.selected {
	background: #f3eeff;
	border-radius: 8px;
	color: #5c53a7 !important;
}

.dontCheck {
	background: #f5eff7 !important;
}

.dontCheckActive {
	background-color: #8e8e93 !important;
}

.selectionIndicator {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	border: 1.5px solid #5c53a7;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white !important;
}

.indicatorActive {
	background-color: #5c53a7;
	position: relative;
	width: 22px;
	height: 22px;
	border-radius: 50%;
}

.indicatorCheck {
	content: '';
	position: absolute;
	left: 8px;
	top: 2px;
	width: 5px;
	height: 13px;
	// border: solid #fffbff;
	border-width: 0 1px 1px 0;
	transform: rotate(45deg);
}

.relative {
	position: relative;
}

.block__links {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.permission_group_item {
	display: flex;
	flex-direction: row;
	// background-color: #f3efff;
	background-color: white;
	/* background-color: aliceblue; */
	padding: 12px 38px 12px 8px;
	// align-items: flex-start;
	gap: 29px;
	align-self: stretch;
	align-items: center;
	& p {
		width: 100px;
	}
}

//Новые стили для checkbox
.groupPermissionItem_radio {
	// width: 25px;
	// height: 25px;
	border-radius: 50%;
	// background: #f3eeff;
	// border-radius: 50%;
	width: 100%;
	height: 100%;
	background-color: lightgray;
	// border: 1.5px solid #FFF;
	color: #5c53a7 !important;
}

.choosenBeforePermissionItem_radio {
	background-color: #5c53a7;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.choosenNowPermissionItem_radio {
	background-color: orange;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.permissionItem_box {
	display: flex;
	flex-direction: row;
	// background-color: #f3efff;
	// background-color: white;
	/* background-color: aliceblue; */
	// padding: 12px 38px 12px 8px;
	align-items: flex-start;
	// gap: 29px;

	align-self: stretch;
}

.permissionItem_round {
	display: flex;
	padding: 1px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	border: 1.5px solid var(--color-checkbox-border, #5c53a7);
	border-radius: 50%;
}

.checkMark {
	// width: 19px;
	// height: 19px;
	// border: 2px solid #000; // Цвет границы круга
	// border-radius: 50%; // Делаем круг
	position: relative;

	&:after {
		content: '';
		position: absolute;
		z-index: 10;
		/* left: 50%; */
		/* bottom: 0; */
		/* bottom: 0; */
		/* right: 0; */
		width: 10px;
		height: 4px;
		border-bottom: 2px solid #fff;
		border-left: 2px solid #fff;
		top: 6px;
		left: 4px;
		// transform: translate(35%, 80%) rotate(-45deg);
		transform: rotate(-45deg);
	}
}
.list_wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.card__user_rights {
	display: grid;
	overflow: auto;
	height: calc(100% - 213px);

	&::-webkit-scrollbar {
		width: 4px;
		height: 8px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}

	@media (min-width: 435.9px) {
		height: calc(100% - 180px);
	}

	@media (min-width: 1280px) {
		height: calc(100% - 42px);
	}
}
.modalRights_wrapper_top {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.additional_to_selectLapTop {
	z-index: 100000;
	width: 96.63%;
	position: absolute;
	top: 120px;
}
.card__btns_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 8px;
	width: 100%;

	@media (min-width: 435.9px) {
		flex-direction: row;
	}
}

.card__empl {
	border: 1px solid #9fc4ff;
	border-radius: 16px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.card__empl_stack {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	@media (min-width: 768px) {
		align-items: flex-end;
		flex-direction: row;
	}

	.card__contacts_row {
		padding: 4px 0 4px 4px;
		display: flex;
		flex-direction: row;
		gap: 8px;

		span {
			color: #1c1c1e;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		}

		button {
			cursor: pointer;
		}
	}
}

.card__empl_img {
	border-radius: 12px;
	min-width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #ffffff;
}

.card__empl_info {
	color: #1c1c1e;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 6px;

	span {
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.08px;
	}

	p {
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.08px;
	}
}

.preloader_inwrapper {
	position: absolute;
	opacity: 0.8;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	flex: 1 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card__blockInput {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	width: 100%;
	gap: 6px;

	> div:first-child {
		width: 100%;
	}

	> button {
		margin-bottom: 6px;
	}
}

.contracts__list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-top: 12px;
}

.contracts__item {
	padding: 8px;
	border-radius: 8px;
	border: 1px solid #9fc4ff;
	background: #fffbff;
	cursor: pointer;
	transition: 0.2s linear;

	&:hover {
		border: 1px solid #9383f9;
		transition: 0.2s linear;
		background: #f2e9ff;
	}

	p {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}
}

.stackClients_header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-right: 16px;

	h5 {
		color: #000;
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0.035px;
	}
}

.modalContracts {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.modalContracts__form {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-right: 16px;
	height: calc(100vh - 252px);
	&.sideBar {
		height: calc(100vh - 68px);
	}
}

.modalNewContract__inputs {
	display: flex;
	width: 100%;
	flex-direction: row;
	gap: 16px;
	align-items: flex-end;

	> div {
		width: 100%;
	}
}

.dropzone__container {
	border-radius: 16px;
	border: 1px solid #2c2c2e;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 100%;

	h6 {
		color: #000;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
}

.dropzone__block {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 20px;
	border: 3px dashed;
	border-color: #5c53a7;
	border-radius: 16px;
	background-color: #e4dfff;
	color: #1c1c1e;
	width: 100%;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.08px;
	position: relative;
	transition: border-color 0.3s linear;
	transition: background-color 0.3s linear;
}

.dropzone__block_success {
	border-color: #149527;
	background-color: #dfffe0;
	transition: border-color 0.3s linear;
	transition: background-color 0.3s linear;
}

.dropzone__icon {
	position: absolute;
	top: 8px;
	right: 0;
}

.dropzone__btn {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 8px;
}

.text__underline {
	text-decoration-line: underline;
}

.preloaderContract {
	background: #0000002e;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	left: 0;
	z-index: 100;
}

[class*='MuiPickersPopper-root'] {
	z-index: 10000 !important;
}

[class*='Mui-selected'] {
	background-color: #1a3355 !important;
}

.modal__card {
	.modal__card1 {
		max-height: calc(100% - 100px);

		@media (min-width: 1280px) {
			max-height: calc(100vh - 182px);
		}
	}

	.card__user_rights {
		height: calc(100% - 188px);

		@media (min-width: 435.9px) {
			height: calc(100% - 128px);
		}

		@media (min-width: 1280px) {
			height: calc(100% - 104px);
		}
	}
}

.MainCardBtns {
	position: absolute;
	bottom: 12px;
	width: calc(100% - 24px);
}
