.mainInfo_mainBlock {
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow: auto;
	padding-right: 12px;

	// скролл
	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}

.mainBlock_row {
	display: flex;
	gap: 8px;
}

.w_100 {
	width: 100%;
}
