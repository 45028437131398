.deleteContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.deleteTitle {
	color: #1c1c1e;
	text-align: center;
	font-family: Montserrat;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0.08px;
}

.damageTitle {
	color: #1c1c1e;
	text-align: center;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px;
}

.actionContainer {
	width: 100%;
	display: flex;
	gap: 4px;
	& > button {
		width: 50%;
	}
}
