.item_wrapper {
	padding: 8px 8px 12px 8px;
	background-color: #fff;
	border-radius: 12px;
}

.modalChange {
	max-height: 100%;
	height: 100%;
	overflow: auto;
	padding-right: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e4dfff;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8f86de;
		border-radius: 4px;
	}

	[class*='item_wrapper'] {
		padding: 8px 0 !important;
	}
}

.modalChange__title {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
}

.modalChange__btn {
	> button {
		width: 100%;
	}
}
