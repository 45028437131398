.TMI_Container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  overflow-y: hidden;
}

.MainData {
  width: 100%;
  background-color: #fff;
  padding: 12px;
  border-radius: 16px;
  height: 100%;
  gap: 12px;
  overflow-y: auto;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.blocks_title {
  color: #1c1c1e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
}
.title_and_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.search_and_button {
  display: flex;
  align-items: center;
  gap: 4px;
}
.MainData_upperPart {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content__card {
  width: 100%;
  border-radius: 24px;
  padding: 12px;
  background: #fff;
  flex-direction: column;
  justify-content: space-between;
  display: none;

  @media (min-width: 639.9px) {
    width: 100%;

    &.show {
      display: flex;
    }
  }
}

@media (min-width: 1279.9px) {
  .MainData_withSidebar {
    width: calc(100% - 49vw);
    flex: unset;
    overflow-y: visible;
    transition: 0.4s ease-in-out;
  }
}
