.photoFix__list {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.photoFix__seal {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap: 4px;

	> div {
		width: 100%;
	}
}

.photoContainer {
	border-radius: 8px;
	border: 2px dashed #aeaeb2;
	display: flex;
	height: 172px;
	min-height: 64px;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 8px;
	align-self: stretch;
}

.selectedPhotos {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 6px;
	gap: 6px;
}
.imageItem {
	width: 172px;
	height: 172px;
	border-radius: 6px;
	background-size: cover;
	background-position: center;
}

.uploadImageWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	&.miniUploadImageWrapper {
		width: 106px;
		height: 106px;
	}
}

.uploadButton {
	cursor: pointer;
	color: #1c1c1e;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.08px;
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 40px 8px;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.viewer {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.onePhotoCard {
	position: relative;
	max-height: 106px;

	& > img {
		max-width: 106px;
		aspect-ratio: 1/1;
		border-radius: 5px;
	}
	& > :last-child {
		position: absolute;
		left: 3px;
		bottom: 3px;
	}
}
