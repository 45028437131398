.container {
	position: relative;
	z-index: 99;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.calendarItems {
	display: flex;
	align-items: center;
	gap: 8px;
}

.startDate {
	width: 150px;
}

.endDate {
	width: 150px;
}

.datePicker {
	position: absolute;
	top: -50%;
	left: 300px;
	z-index: 999;
	display: flex;
	width: 483px;
}

.inputContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.radioItem {
	display: flex;
	gap: 8px;
}

.radioInput {
	width: 20px;
	height: 20px;
}
