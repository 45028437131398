.account__step {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
	width: 100%;

	> div {
		width: 100%;
		transition: 0.4s ease-in-out;
	}
}

.choiceClient_withSidebar {
	width: calc(100% - 49vw);
	transition: 0.4s ease-in-out;
}

// .account__step4 {
//   > div {
//     width: 50%;
//   }
// }

.mob_account__step {
	> div {
		position: absolute;
		bottom: 56px;
		width: 100%;
		left: 0px;
		border-radius: 24px 24px 0px 0px !important;
	}
}

.selectAutoBlockStep4 {
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 100%;
	> div {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.mainBlocksStep4 {
	display: flex;
	height: 100%;
	max-height: calc(100svh - 266px);
}

.export_step3 > div {
	width: 50%;
}

// стили для модального окна
.ContModal {
	> div > div > div:last-child > div > div > div {
		position: relative;
		height: 100%;
		bottom: 0px;
		> div {
			border-radius: 0px;
		}
	}
}

.modal {
	> div {
		width: 100%;
	}
	> div > div > div:last-child > div {
		padding: 0px !important;
		> div > div > div {
			box-shadow: none;
			max-height: calc(100svh - 178px);
			> div:last-child > form {
				> div:first-child {
					padding-bottom: 80px;
				}
				> div:last-child {
					position: fixed;
					bottom: 0;
					width: 100%;
					left: 0;
					padding: 12px;
					background: #fffbff;
					height: fit-content;
				}
			}
		}
	}
}

.swap {
	width: 100%;
	height: 100%;
	background-color: #fffbff;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}
