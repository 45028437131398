.TMI_Container {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	position: relative;
	// height: 300px;
}

.MainData {
	width: 100%;
	background-color: #fff;
	padding: 12px;
	border-radius: 16px;
	// height: 95%;
	@media (min-width: 639px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.blocks_title {
	color: #1c1c1e;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 12px;
}

.actionContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 12px;

	@media (min-width: 639px) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.modulesSettings_form {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	align-content: flex-start;
	align-items: flex-start;

	height: calc(100svh - 368px);
	overflow-y: auto;
	overflow-x: hidden;
}

.modulesSettings_form::-webkit-scrollbar {
	width: 3px;
}

.modulesSettings_form::-webkit-scrollbar-track {
	background-color: #e4dfff;
	border-radius: 2px;
}

.modulesSettings_form::-webkit-scrollbar-thumb {
	background-color: #8f86de;
	border-radius: 2px;
}

.modulesSettings_formBlock {
	display: flex;
	width: 100%;
	min-width: 250px;
	// min-width: 280px;
	// max-width: calc(33.33% - 16px);
	padding: 8px 12px 12px 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	border-radius: 12px;
	border: 1px solid var(--core-primary-60, #8f86de);
	flex: 1 0 calc(33.33% - 16px);
	@media (min-width: 450px) {
		min-width: 280px;
		max-width: calc(33.33% - 16px);
	}
}
.modulesSettings_formBlock_item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	display: flex;
	padding: 6px 16px 6px 0px;
	gap: 4px;
	flex: 1 0 0;

	p {
		color: var(--core-dark-10, #1c1c1e);
		font-family: Montserrat;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px; /* 133.333% */
		letter-spacing: 0.035px;
		padding-left: 12px;
	}
	span {
		color: var(--core-dark-10, #1c1c1e);
		font-family: Montserrat;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		letter-spacing: 0.035px;
		padding-left: 12px;
	}
}

.actionContainerSet {
	display: flex;
	justify-content: flex-end;
	position: fixed;
	gap: 8px;

	@media (min-width: 320px) {
		right: 16px;
		bottom: 72px;
	}
	@media (min-width: 350px) {
		// right: 32px;
		// bottom: 90px;
	}

	@media (min-width: 768px) {
		right: 32px;
		bottom: 32px;
	}
}

.actionContainerMob {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
}
