.cardContainer {
	position: relative;
	display: flex;
	padding: 10px 12px 10px 28px;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 16px;
	border: 2px solid #9fc4ff;
	@media (max-width: 550px) {
		flex-direction: column;
	}
}

.orderStatus {
	display: flex;
	align-items: center;
	gap: 8px;
}

.driverTransport,
.responsibleSecurity {
	margin-top: 12px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.driverTransport,
.leftSide,
.responsibleSecurity p {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.08px;
}

.orderStatus {
	& > :first-child {
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: 0.08px;
	}
	& > :last-child {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: 0.08px;
	}
}

.checkContainer {
	position: absolute;
	left: -16px;
	top: 50%;
	width: 32px;
	height: 32px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #ff8e16;
	transform: translateY(-50%);
}

.border {
	position: relative;
	width: 30px;
	height: 30px;
	border: 1px solid #fff;
	border-radius: 6px;
}

.galka {
	position: absolute;
	top: 6px;
	width: 9.17px;
	height: 15.3px;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	border-radius: 2px;
	transform: rotate(45deg);
}
