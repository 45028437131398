.textInfo > div > div:last-child {
	padding: 12px !important;
	height: 68px;

	> div {
		height: 100%;
	}
}

.textInfo {
	padding-bottom: 16px;
}

.desc_main_block {
	display: flex;
	flex-direction: column;
}

.addPTitle {
	color: #1c1c1e;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px; /* 125% */
	letter-spacing: 0.035px;
	padding-bottom: 16px;
}

.inputs_block {
	display: flex;
	justify-content: space-between;
	gap: 21px;
}

.commentTextArea {
	width: 100%;
	height: 250px;
	background-color: #fff;
	border: 1px solid #0053da;
	border-radius: 8px;
	padding: 12px 12px;
	font-size: 16px;
}
