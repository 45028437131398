.mainInfoBlock {
	border-radius: 16px;
	background: #fffbff;
	padding: 12px;
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.smallLong {
	max-width: 40%;
}

.longInfo_mainBlock {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 24px;
	width: 100%;
	height: 100%;
}

.infoP_btns {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 34px;
	height: 100%;
}

.infoP {
	display: flex;
	flex-direction: column;
	gap: 8px;
	> p:first-child {
		color: #1c1c1e;
		font-size: 18px;
		font-weight: 600;
		line-height: 26px; /* 144.444% */
	}
	> p:last-child {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px; /* 157.143% */
	}
}

.states__container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	height: 100%;
}

.states {
	display: flex;
	align-items: center;
	gap: 8px;
	> div {
		width: 44px;
		height: 44px;
		border-radius: 100px;
	}
	.green {
		border: 2px solid #149527;
	}
	.orange {
		border: 2px solid #ff8e16;
		background: #ff8e16;
	}
	.primary {
		border: 2px solid #0053da;
	}
}

.progressP {
	display: flex;
	flex-direction: column;
	gap: 4px;
	> p {
		color: #000;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.035px;
	}
}

.longInfo_mainBlock_progress {
	align-items: center !important;
	width: 100%;
	height: 100%;
}

.mainMediumBlock {
	display: flex;
	width: 100%;
	justify-content: space-between;
	height: 100%;
	> form {
		width: 100%;
		> div > div:first-child > div {
			width: 100%;
		}
	}
}

.firstBlock {
	display: flex;
	gap: 14px;
	flex-direction: column;
	width: 100%;
}

.titleSubTitle {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.title {
	color: #1c1c1e;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px; /* 150% */
}

.subTitle {
	color: #5c53a7;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px; /* 157.143% */
}

.fullInfo {
	display: flex;
	flex-direction: column;
	gap: 10px;
	> div,
	p {
		display: flex;
		align-items: center;
		gap: 8px;
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		> p {
			font-weight: 400 !important;
		}
	}
}

.contractNumber {
	color: #5c53a7 !important;
}

.smallInfo {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
}

.mainBlock {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	align-items: flex-start;
}

.moreInfoContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 12px;
	> div {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-right: -12px;
		margin-left: -12px;
		padding-left: 12px;
	}
	> div:first-child {
		background: #eaf1ff;
	}
	> div:last-child {
		background: #f3eeff;
	}
}

.lineInfo {
	display: flex;
	align-items: center;
	gap: 4px;
	color: #1c1c1e;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px; /* 150% */
	> p {
		font-weight: 600 !important;
	}
}

.moreInfo {
	display: flex;
	align-items: center;
	color: #1c1c1e;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.5px;
	border-radius: 6px;
	background: #f3eeff;
	padding: 4px;
	gap: 12px;
	margin-top: 12px;
	justify-content: space-between;
	cursor: pointer;
	width: fit-content;
}

.arrowMoreInfo {
	> div {
		transform: rotate(180deg);
	}
}

.main_absenceEmployee_block {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	> p {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px; /* 157.143% */
	}
}
