.block {
  border-radius: 12px;
  background: #fffbff;
  padding: 16px;
  gap: 20px;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
}

.block h5 {
  color: #000;
  font-family: "AvenirNextCyr";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.24px;
  text-align: center;
}

.block__choice {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.block__btns {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.block__descr p {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
  margin-top: 8px;
}

.block__inps {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.block__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.block__info p {
  color: #2d2276;
  font-family: "AvenirNextCyr";
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.035px;
}

.block__list {
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.block__list li {
  gap: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
  color: #2c2c2e;
  font-family: "AvenirNextCyr";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  @media (min-width: 768px) {
    &:hover {
      cursor: pointer;
      background: #f3eeff !important;
      border-radius: 8px;
      color: #5c53a7;
    }
  }
}

.selected {
  background: #f3eeff;
  border-radius: 8px;
  color: #5c53a7 !important;
}

.dontCheck {
  background: #F5EFF7 !important;
}

.dontCheckActive {
  background-color: #8E8E93 !important;
}

.selectionIndicator {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1.5px solid #5c53a7;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
}

.indicatorActive {
  background-color: #5c53a7;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.indicatorCheck {
  content: "";
  position: absolute;
  left: 8px;
  top: 2px;
  width: 5px;
  height: 13px;
  border: solid #fffbff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.relative {
  position: relative;
}

.block__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
