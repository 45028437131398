.containerPass {
	display: flex;
	gap: 12px;
}

.filtersPass {
	width: 30%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	@media (max-width: 1200px) {
		display: none;
	}
}

.allContainers {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 12px;
	background-color: #fff;
	border-radius: 12px;

	& > :first-child {
		font-size: 14px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.108px;
	}
	& > :last-child {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.108px;
	}
}

.filters {
	height: 100%;
	padding: 12px 16px;
	background-color: #fff;
	border-radius: 12px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e4dfff;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8f86de;
		border-radius: 4px;
	}
}

.resetFilters {
	display: flex;
	justify-content: space-between;
	align-items: center;

	& p {
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0.035px;
	}
}

.selects {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding-top: 12px;
}

.mainPass {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 70%;
	padding: 16px;
	background-color: #fff;
	border-radius: 12px;
	@media (max-width: 1200px) {
		width: 100%;
	}
}

.buttonsBlock {
	display: flex;
	gap: 8px;
}

.passList {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-left: 16px;
	overflow: auto;
}

.statusSelect {
	display: flex;
	flex-direction: column;
	gap: 8px;
	& p {
		color: #1c1c1e;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
	}
}
