.main_block {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 12px;
}

.acts_block {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow: auto;
	// скролл
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}

.reportsList_wrapper_preloader {
	position: absolute;
	opacity: 0.8;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	flex: 1 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}
