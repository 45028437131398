.account__wrap {
	min-width: 100%;

	@media (min-width: 1279.9px) {
		min-width: calc(100% - 256px);
		width: 100%;
	}
}

.account__container {
	background: #ebebeb;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: calc(100% - 100px);
	padding: 16px 0 0 0;

	@media (min-width: 639.9px) {
		height: calc(100% - 52px);
		padding: 16px;
	}
}

.inner_slider {
	padding-left: 16px;
}

.account__container_withSlider {
	background: #ebebeb;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 16px 0 0 0;

	@media (min-width: 639.9px) {
		padding: 16px;
	}
}

.header_btns {
	display: flex;
	gap: 8px;
	align-items: center;
}

.account__slider {
	overflow: hidden;
	padding: 0px 16px;
	min-height: 36px;

	@media (min-width: 639.9px) {
		padding: 0;
	}
}

.account__stack,
.contentBtns {
	height: 100%;
	padding: 16px 8px;
	border-radius: 24px;
	background: rgba(255, 251, 255, 0.92);
	box-shadow:
		0px 8px 24px -4px rgba(24, 39, 75, 0.08),
		0px 6px 12px -6px rgba(24, 39, 75, 0.12);

	@media (min-width: 639.9px) {
		padding: 16px;
	}
}

.account__stackCars {
	display: flex;
	padding: 20px;
	flex-direction: row;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;
	align-self: stretch;
	border-radius: 24px;
	background: rgba(255, 255, 255, 0.95);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	height: calc(100svh - 76px);

	@media (max-width: 639.9px) {
		position: absolute;
		left: 0;
		bottom: 38px;
		width: 100% !important;
	}
}

.firstStepLine {
	justify-content: flex-end !important;
}

.mbTopLine {
	padding: 0px 12px;
	background: var(--core-surfaces-dark-201, rgba(38, 49, 65, 0.96));
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	> div {
		display: flex;
		gap: 4px;
		padding: 8px 0px;
		align-items: center;
		cursor: pointer;
	}
	div,
	p {
		color: #fffbff;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}
}

.header_btns {
	display: flex;
	gap: 8px;
}

.bottomBtns {
	display: flex;
	justify-content: flex-end;
}

.contentBtns {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;

	> div:first-child {
		height: calc(100svh - 165px);
	}

	> div:first-child[class*='style_account__step'] {
		height: calc(100svh - 208px);
	}
}
.orderPageBtn {
	height: calc(100svh - 128px);

	.lowerBtns_spaceBetween {
		display: flex;
		width: 100%;
		flex-direction: row;
		// justify-content: flex-end;
		justify-content: space-between;
	}
	.lowerBtns_flexEnd {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: flex-end;
	}
}
.nextPrevBtns {
	display: flex;
	gap: 8px;
}

.bottomBtnsOrderStep3 {
	// justify-content: space-between !important;
}

.account__preloaderTerminals {
	height: 94px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ebebeb;
}

.account__flex {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.btnExport > button {
	min-width: 203px;
}

//Стили для приемки
.main_block {
	height: 100%;
	border-radius: 16px;
	background: #fff;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 12px;
	position: relative;
	overflow: auto;
}

.main_block_title {
	color: #000;
	font-size: 18px;
	font-weight: 600;
	line-height: 26px; /* 144.444% */
}

.bottom_btns {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	align-items: center;
	position: relative;
	margin-top: auto;
}
.items_wrapper {
	height: 50%;
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.span_wrapper {
	height: 3.25%;
	margin-bottom: 8px;
	span {
		font-size: 16px;
		font-weight: 600;
	}
}
.button_wrapper {
	padding: 8px;
	display: flex;
	height: 46.75%;
	align-items: flex-end;
	justify-content: flex-end;
}

.btnMobileExport > button {
	min-width: 81px;
}

.item_wrapper {
	padding: 8px 8px 12px 8px;
	background-color: #fff;
	border-radius: 12px;
}

.item__inps {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 16px;
	align-items: center;

	p {
		color: #1c1c1e;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
		margin-bottom: 6px;
	}

	> div {
		width: 100%;

		> div {
			width: 100%;
		}
	}
}

.item__switch {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
}
