.block {
  border-radius: 12px;
  background: #fffbff;
  padding: 16px;
  gap: 20px;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
}

.block h5 {
  color: #000;
  font-family: "AvenirNextCyr";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.24px;
  text-align: center;
}

.block__choice {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.block__btns {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.block__descr p {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
  margin-top: 8px;
}

.block__inps {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.block__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.block__info p {
  color: #2d2276;
  font-family: "AvenirNextCyr";
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.035px;
}

.block__links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
