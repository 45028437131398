.card {
	background-color: #fff;
	border-radius: 12px;
	padding: 8px 8px 12px 12px;
	border: 1px solid #e0e0e0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 100%;
	cursor: pointer;
}

.cardHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}

.cardTitle {
	font-size: 16px;
	font-weight: 600;
	color: #333;
}

.cardDescription {
	font-size: 14px;
	color: #666;
}

.customCheckbox {
	display: inline-block;
	position: relative;
}

.checkboxInput {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.checkboxCustom {
	width: 20px;
	height: 20px;
	border: 1px solid #2d2276;
	border-radius: 4px;
	position: relative;
	display: inline-block;
	padding: 1px;
	box-sizing: border-box;
}

.checkboxInput:checked + .checkboxCustom {
	background-color: #ff8e16;
	border: 1px solid #2d2276;
}

.checkboxInput:checked + .checkboxCustom::after {
	content: '';
	position: absolute;
	left: 6px;
	top: 1px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

//style Step2

.stepContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: #f3eeff;
	height: 100%;
	border-radius: 16px;
	padding: 12px 8px 20px 8px;
}

.title {
	color: #000;
	font-family: Montserrat;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.documentContainer {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.actionButton {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	margin-top: auto;
	& button {
		width: 100%;
	}
}

.actionPrintSend {
	display: flex;
	gap: 8px;
}
