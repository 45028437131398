.photoContainer {
	display: flex;
	flex-wrap: wrap;
}

.onePhotoCard {
	position: relative;
	margin: 5px;
	& > img {
		width: 150px;
		height: 100px;
		border-radius: 5px;
		cursor: pointer;
	}
	& > :last-child {
		position: absolute;
		left: 3px;
		bottom: 3px;
	}
}
