.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	padding: 8px 10px 8px 6px;
	width: fit-content;
	// max-width: 194px;
	// width: 100% !important;
	border-radius: 8px;
	border: 1px solid #c8c8c8;
	background: #ffffff;
	backdrop-filter: blur(2px);
	cursor: pointer;
	transition: 0.3s;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	gap: 4px;

	span {
		color: #000000;
		font-size: 14px;
		font-weight: 500;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.08px;
	}

	&:hover {
		border: 1px solid #78acff;
	}

	&.active {
		border: 1px solid #0053da;
	}
}

.swiper-slide.selected {
	border: 1px solid #0053da;
}
