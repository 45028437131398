.mainMain_block {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 8px;
	> button {
		justify-content: space-between !important;
	}
}

.main_block {
	height: 100%;
	// border-radius: 16px;
	// background: #fff;
	display: flex;
	flex-direction: column;
	gap: 16px;
	// padding: 12px;
	position: relative;
	overflow: auto;
}

.main_block_title {
	color: #000;
	font-size: 18px;
	font-weight: 600;
	line-height: 26px; /* 144.444% */
}

.bottom_btns {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	align-items: center;
	position: relative;
}

.bottom_first_left {
	> button:first-child {
		position: absolute;
		left: 0;
	}
}

.bottom_btns_left {
	justify-content: flex-start !important;
}

.page2_modal_main {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	align-items: center;
	> p {
		color: #1c1c1e;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px; /* 150% */
	}
}

.page2_modal_btns {
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 12px;
	> button {
		width: 100%;
	}
}

.main_content {
	overflow: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}

	padding-right: 0; /* Изначально нет padding-right */
}

.scroll_content {
	padding-right: 12px !important;
}

.modal_btns_sB {
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 12px;
}
