.orderList_wrapper {
	// position: relative;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 0px 12px 0px 0px;
	flex: 1 0;
	height: 100%;
	gap: 8px;
	border-radius: 0 0 24px 24px;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	// * TODO обсудить актуальность zoom
	zoom: 0.84;
}
.orderList_wrapper_preloader {
	position: absolute;
	opacity: 0.8;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	flex: 1 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}
.content {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 0px 12px 0px 0px;
	flex: 1 0;
	height: 100%;
	position: relative;
	// max-height: calc(100vh - 228px);
	// min-height: calc(100vh - 228px);

	@media (min-width: 1279.9px) {
		// max-height: calc(100vh - 216px);
		// min-height: calc(100vh - 216px);
	}
}
.orderList_wrapper::-webkit-scrollbar {
	width: 2px; /* ширина всей полосы прокрутки */
}

.orderList_wrapper::-webkit-scrollbar-track {
	background: #ffede3; /* цвет зоны отслеживания */
	height: 90%;
	margin-bottom: 20px;
}

.orderList_wrapper::-webkit-scrollbar-thumb {
	background-color: #ff8e16; /* цвет бегунка */
	border-radius: 4px; /* округлось бегунка */
	height: 48px;
	border: 3px solid orange;
}

@media (max-width: 639.9px) {
	.orderList_wrapper {
		max-height: calc(100vh - 168px);
	}
}

.orderList_wrapper__nonResult {
	text-align: center;
	margin-top: 12px;
}
