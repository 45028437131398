.stepContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: #f3eeff;
	height: 100%;
	border-radius: 16px;
	padding: 12px 8px 20px 8px;
}

.title {
	color: #000;
	font-family: Montserrat;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.stepContainer_info {
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-y: auto;
}

.actCard {
	display: flex;
	flex-direction: column;
	align-items: start;
	background-color: #fff;
	border-radius: 12px;
	padding: 12px;
}

.actCard_title {
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
	margin-bottom: 8px;
}

.actCard_date {
	margin-top: 4px;
	margin-bottom: 8px;
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.actCard_status {
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.passCard {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 12px;
}

.passCard_top {
	padding: 12px 12px 8px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
}

.passCard_bottom {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 4px 12px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}
}

.autoCard {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 12px;
}

.autoCard_top {
	padding: 12px 12px 8px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
}

.autoCard_bottom {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 4px 12px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}
}

.transceiverCard {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 12px;
}

.transceiverCard_top {
	padding: 12px 12px 8px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
}

.transceiverCard_bottom {
	padding: 4px 12px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}
}

.containerCard {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 12px;
}

.containerCard_top {
	padding: 12px 12px 8px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
}

.containerCard_bottom {
	display: flex;
	justify-content: space-between;
	padding: 4px 12px 12px;
}

.containerCard_bottom_info {
	display: flex;
	gap: 8px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}
}

.containerCard__details {
	display: flex;

	.details__danger {
		width: 16px;
		height: 16px;
		background-color: #de3730;
		border-radius: 2.667px;
	}

	.details__damage {
		width: 16px;
		height: 16px;
		background-color: #ff8e16;
		border-radius: 2.667px;
	}
}

.clientCard {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 12px;
}

.clientCard_top {
	padding: 12px 12px 8px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.08px;
	}
}

.clientCard_bottom {
	padding: 4px 12px 12px;
	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}
}

.actionButton {
	margin-top: auto;
	width: 100%;
	& button {
		width: 100%;
	}
}
