.main_block_container_list {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	overflow-y: auto;
	gap: 12px;
}

.containers_list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-y: auto;
	height: 100%;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}

.containers_bottom_buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	> div:first-child {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}
