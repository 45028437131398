.TMI_Container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	@media (min-width: 639px) {
		& button {
			width: fit-content;
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}

.MainData {
	width: 100%;
	background-color: #fff;
	padding: 12px;
	border-radius: 16px;
}

.MainData::-webkit-scrollbar {
	width: 3px;
}

.MainData::-webkit-scrollbar-track {
	background-color: #e4dfff;
	border-radius: 2px;
}

.MainData::-webkit-scrollbar-thumb {
	background-color: #8f86de;
	border-radius: 2px;
}

.blocks_title {
	color: #1c1c1e;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 12px;
}

//fake(del it)
.fakeInput {
	width: 100%;
	height: 60px;

	.grey_plank {
		height: 40px;
		width: 100%;
		background-color: grey;
	}
}

.dateContainer {
	display: flex;
	align-items: flex-end;
}

.TMI_Form {
	display: flex;
	width: 100%;
	gap: 24px;
	overflow-y: auto !important;
}

.TMI_Form_inputs {
	gap: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
	@media (min-width: 1280px) {
		max-width: 50%;
	}
}

.actionContainer {
	display: flex;
	justify-content: flex-end;
	position: fixed;
	right: 8px;
	bottom: 72px;

	@media (min-width: 639.9px) {
		right: 32px;
	}

	@media (min-width: 1279.9px) {
		bottom: 32px;
	}
}
