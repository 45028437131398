.universal-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	text-decoration: none;
	font-family: Montserrat;
	// transition: background-color 0.3s, color 0.3s, border-color 0.3s;
	outline: none;
	border-radius: 6px;
	transition: background-color 0.2s ease-in-out;

	&.color-primary {
		background-color: #0053da;
		color: #ffffff;

		&:hover {
			background-color: #014cc6;
		}

		&:active {
			background-color: #0040a7;
		}

		&:disabled {
			background-color: #8e8e93;
			color: #f2f2f7;
			cursor: not-allowed;

			& svg path {
				fill: #f2f2f7 !important;
			}
		}

		&.type-with-border {
			background-color: transparent;
			border: 1px solid #0053da;
			color: #0053da;

			&:hover {
				color: #014cc6;
				border: 1px solid #014cc6;
				& svg path {
					fill: #014cc6 !important;
				}
			}

			&:active {
				color: #0040a7;
				border: 1px solid #0040a7;
				& svg path {
					fill: #0040a7 !important;
				}
			}

			&:disabled {
				color: #48484a;
				border: 1px solid #48484a;
				cursor: not-allowed;

				& svg path {
					fill: #48484a !important;
				}
			}
		}
	}

	&.color-accent {
		background-color: #ff8e16;
		color: #ffffff;

		&:hover {
			background-color: #b56100;
			& svg path {
				fill: #fdfcff;
			}
		}

		&:active {
			background-color: #914d00;
			& svg path {
				fill: #fffbff;
			}
		}

		&:disabled {
			background-color: #8e8e93;
			color: #f2f2f7;
			cursor: not-allowed;

			& svg path {
				fill: #f2f2f7 !important;
			}
		}

		&.type-with-border {
			background-color: transparent;
			border: 1px solid #ff8e16;
			color: #ff8e16;

			&:hover {
				color: #b56100;
				border: 1px solid #b56100;
				& svg path {
					fill: #b56100 !important;
				}
			}

			&:active {
				color: #914d00;
				border: 1px solid #914d00;
				& svg path {
					fill: #b56100 !important;
				}
			}

			&:disabled {
				color: #48484a;
				border: 1px solid #48484a;
				cursor: not-allowed;

				& svg path {
					fill: #48484a !important;
				}
			}
		}
	}

	&.color-error {
		background-color: #ba1a1a;
		color: #ffffff;

		&:hover {
			background-color: #93000a;
		}

		&:active {
			background-color: #690005;
		}

		&:disabled {
			background-color: #8e8e93;
			color: #f2f2f7;
			cursor: not-allowed;

			& svg path {
				fill: #f2f2f7 !important;
			}
		}

		&.type-with-border {
			background-color: transparent;
			border: 1px solid #ba1a1a;
			color: #ba1a1a;

			&:hover {
				color: #93000a;
				border: 1px solid #93000a;
				& svg path {
					fill: #93000a !important;
				}
			}

			&:active {
				color: #690005;
				border: 1px solid #690005;
				& svg path {
					fill: #690005 !important;
				}
			}

			&:disabled {
				color: #48484a;
				border: 1px solid #48484a;
				cursor: not-allowed;

				& svg path {
					fill: #48484a !important;
				}
			}
		}
	}

	&.color-succesful {
		background-color: #149527;
		color: #ffffff;

		&:hover {
			background-color: #0f701d;
		}

		&:active {
			background-color: #094312;
		}

		&:disabled {
			background-color: #8e8e93;
			color: #f2f2f7;
			cursor: not-allowed;

			& svg path {
				fill: #f2f2f7 !important;
			}
		}

		&.type-with-border {
			background-color: transparent;
			border: 1px solid #149527;
			color: #149527;

			&:hover {
				color: #0f701d;
				border: 1px solid #0f701d;
				& svg path {
					fill: #0f701d !important;
				}
			}

			&:active {
				color: #094312;
				border: 1px solid #094312;
				& svg path {
					fill: #094312 !important;
				}
			}

			&:disabled {
				color: #48484a;
				border: 1px solid #48484a;
				cursor: not-allowed;

				& svg path {
					fill: #48484a !important;
				}
			}
		}
	}

	&.color-transparent {
		background-color: transparent;
		color: #ffffff;

		&:hover {
			background-color: transparent;
			color: #0053da;
			& svg path {
				fill: #0053da;
			}
		}

		&:active {
			background-color: transparent;
			color: #0040a7;
			& svg path {
				fill: #0040a7;
			}
		}

		&:disabled {
			background-color: #8e8e93;
			color: #f2f2f7;
			cursor: not-allowed;

			& svg path {
				fill: #f2f2f7 !important;
			}
		}

		&.type-with-border {
			background-color: transparent;
			border: 1px solid #fffbff;
			color: #fffbff;

			&:hover {
				color: #0053da;
				border: 1px solid #0053da;
				& svg path {
					fill: #0053da !important;
				}
			}

			&:active {
				color: #0040a7;
				border: 1px solid #0040a7;
				& svg path {
					fill: #0040a7 !important;
				}
			}

			&:disabled {
				color: #48484a;
				border: 1px solid #48484a;
				cursor: not-allowed;

				& svg path {
					fill: #48484a !important;
				}
			}
		}
	}

	&.color-secondary {
		background-color: #535f70;
		color: #fffbff;

		&:hover {
			background-color: #3c4858;
			color: #fffbff;
		}

		&:active {
			background-color: #263141;
			color: #fffbff;
		}

		&:disabled {
			background-color: #8e8e93;
			color: #f2f2f7;
			cursor: not-allowed;

			& svg path {
				fill: #f2f2f7 !important;
			}
		}

		// &.type-with-border {
		//   background-color: transparent;
		//   border: 1px solid #535f70;
		//   color: #fffbff;

		//   &:hover {
		//     color: #5c53a7;
		//     border: 1px solid #5c53a7;
		//     & svg path {
		//       fill: #5c53a7 !important;
		//     }
		//   }

		//   &:active {
		//     color: #2d2276;
		//     border: 1px solid #2d2276;
		//     & svg path {
		//       fill: #2d2276 !important;
		//     }
		//   }

		//   &:disabled {
		//     color: #48484a;
		//     border: 1px solid #48484a;
		//     cursor: not-allowed;

		//     & svg path {
		//       fill: #48484a !important;
		//     }
		//   }

		//   &.size-small {
		//     padding: 4px 8px;
		//   }
		//   &.size-medium {
		//     padding: 6px 8px;
		//   }
		//   &.size-large {
		//     padding: 8px 10px;
		//   }
		// }
	}

	&.size-small {
		font-size: 12px;
		padding: 6px 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: 0.08px;
		max-height: 28px;
	}

	&.size-medium {
		font-size: 14px;
		padding: 8px 10px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.08px;
		border-radius: 8px;
		max-height: 36px;
	}

	&.size-large {
		font-size: 16px;
		padding: 10px 12px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.08px;
		border-radius: 10px;
		max-height: 44px;
	}

	&.with-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		.button-icon {
			// Стили для иконки
		}

		.button-label {
			// Стили для текста
		}

		&.size-small {
			padding: 6px 6px 6px 8px;
		}

		&.size-medium {
			padding: 8px 8px 8px 10px;
		}

		&.size-large {
			padding: 10px 10px 10px 12px;
		}
	}

	&:hover {
		// Дополнительные стили для ховера
	}

	&:active {
		// Дополнительные стили для активного состояния
	}

	&:disabled {
		// Дополнительные стили для дизаблед состояния
	}

	&.with-icon-only {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		&.size-small {
			padding: 6px;
		}

		&.size-medium {
			padding: 8px;
		}

		&.size-large {
			padding: 10px;
		}
	}
}
