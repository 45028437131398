.layout {
	display: flex;
	flex-direction: column;
	gap: 32px;
	justify-content: space-between;
	//! 25.04.2024 Поменял
	height: 100%;

	@media (min-width: 567.9px) {
		padding: 0;
	}

	@media (max-height: 700px) {
		gap: 8px;
	}
}

.layout__stack {
	//! 25.04.2024 поменял
	height: 100%;
	@media (min-width: 639.9px) {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
}

.layout__slider {
	display: none;

	@media (min-width: 639.9px) {
		display: block;
	}
}

.layout__footer {
	display: none;
	align-items: center;
	justify-content: flex-end;
	background-color: #263141;
	padding: 12px 20px;
	border-radius: 0 0 24px 24px;

	// @media (min-width: 1023.9px) {
	//   display: flex;
	// }
}

.page__block {
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: center;
	gap: 20px;
}

.page__items {
	border-radius: 8px;
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ffffff;
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.08px;
}

.content {
	display: flex;
	position: relative;
	max-height: calc(100vh - 248px);
	min-height: calc(100vh - 248px);

	@media (min-width: 1279.9px) {
		max-height: calc(100vh - 216px);
		min-height: calc(100vh - 216px);
	}
}

.layout__content {
	//25.04.2024
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	//! 25.04.2024 поменял
	height: 100%;
}

@media (min-width: 1279.9px) {
	.layout__content_withSidebar {
		width: calc(100% - 49vw);
		transition: 0.4s ease-in-out;
	}
}

.search {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 8px;
}

.search__btns {
	align-items: center;
	gap: 8px;
	padding: 4px;
	display: none;

	@media (min-width: 567.9px) {
		display: flex;
	}
}

.content__card {
	width: 100%;
	border-radius: 24px;
	padding: 12px;
	background: #fff;
	flex-direction: column;
	justify-content: space-between;
	display: none;

	@media (min-width: 1279.9px) {
		width: 100%;

		&.show {
			display: flex;
		}
	}
}

.showRights {
	display: flex;
}

.card__user {
	display: flex;
	flex-direction: row;
	gap: 24px;
	margin-bottom: 12px;
}
.card__img {
	border-radius: 50%;
	min-width: 56px;
	height: 56px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;

	@media (min-width: 1224px) {
		border-radius: 26px;
		min-width: 120px;
		height: 120px;
		font-size: 50px;
	}

	@media (max-height: 700px) {
		min-width: 80px;
		height: 80px;
	}
}

.card__info {
	color: #000;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 8px;

	span {
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
	}

	p {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
	}
}

.card__btns {
	display: flex;
	flex-direction: column;
	gap: 8px;

	div {
		display: flex;
		flex-direction: row;
		gap: 8px;

		button {
			width: 100%;
		}
	}

	@media (min-width: 1224px) {
		flex-direction: row;
	}
}

.layoutOrder__leftSide {
	display: flex;
	flex-direction: column;
}
.content_container {
	display: flex;
	align-items: flex-start;
	gap: 20px;
	flex: 1 0 0;
	align-self: stretch;
}

@media (max-width: 1279.9px) {
	.search {
		position: fixed;
		bottom: 54px;
		z-index: 1;
		left: 0;
		background: rgba(83, 95, 112, 0.96);
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		width: 100%;
		padding: 8px;

		> .search__btns {
			display: none;
		}
	}
}

.preloader_wrapper {
	position: absolute;
	opacity: 0.8;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	flex: 1 0 0;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: 1279.9px) {
		left: 256px;
	}
}

.preloader_inwrapper {
	position: absolute;
	opacity: 0.8;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	flex: 1 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.inner_slider {
	overflow: hidden;
}

.terminal__content {
	display: flex;
	max-height: calc(100vh - 305px);
	min-height: calc(100vh - 305px);

	@media (min-width: 1279.9px) {
		max-height: calc(100vh - 266px);
		min-height: calc(100vh - 266px);
	}
}

.whiteBoard {
	width: 100%;
	height: 100%;
	padding: 12px;
	background-color: #fff;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	// height: 600px;
}

.jobContent {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
	align-content: flex-start;
	padding-right: 8px;
	overflow-x: hidden !important;
	width: 100%;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e4dfff;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8f86de;
		border-radius: 2px;
	}
}

.layoutOrders {
	display: flex;
	gap: 8px;
	height: 100%;
}

.orders_filtrBlock {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.searchLine {
	display: flex;
	gap: 8px;
	align-items: center;
}

.ordersList_search {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	background: #fff;
	padding: 16px;
}

.layoutFlex {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.layout__blockInfo {
	width: 50%;
	max-width: 356px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.layout__blockList {
	width: 100%;

	[class*='Modals_card__userInfo'] {
		max-height: calc(100vh - 254px);
	}
}

.layout__stackClients {
	padding: 16px 0 16px 16px;
	border-radius: 16px;
	background: #fff;
	height: 100%;
	min-height: calc(100vh - 174px);
	position: relative;
}

.layout__info {
	padding: 10px 12px;
	border-radius: 16px;
	background: #fff;
	border: 2px solid #ffdcc3;

	p {
		color: #000;
		font-size: 14px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.108px;
	}
}

.layout__filters {
	padding: 12px 16px;
	border-radius: 16px;
	background: #fff;
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 100%;
	position: relative;

	h6 {
		color: #000;
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0.035px;
	}
}

.filter_select {
	position: relative;
}

.client__card {
	position: relative;
	color: #1c1c1e;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	border-radius: 16px;
	background: #fff;
	padding: 4px;
	transition:
		background 0.3s,
		width 0.5s;
	height: 64px;
	cursor: pointer;
	border-radius: 16px;
	transition: 0.2s ease-in-out;
	border: 2px solid #9fc4ff;
	width: 100%;
}

.client__card_list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	overflow-y: scroll;
	padding-right: 4px;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 2px;
	}
}

.client__card_link {
	width: 100%;
}

.client__card_big {
	position: relative;
	color: #1c1c1e;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 16px;
	background: #fff;
	padding: 4px;
	transition:
		background 0.3s,
		width 0.5s;
	height: 64px;
	cursor: pointer;
	border-radius: 16px;
	transition: 0.2s ease-in-out;
	border: 2px solid #9fc4ff;
	width: 100%;

	> div:first-child {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
	}
}

.client__img {
	border-radius: 12px;
	min-width: 56px;
	height: 56px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
}

.client__info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	span {
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: 0.08px;
	}

	p {
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.08px;
	}
}

.layout__infoFlex {
	display: flex;
	flex-direction: row;
	gap: 8px;

	button {
		background-color: #fffbff;
	}
}

.stackClients_header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-right: 16px;

	h5 {
		color: #000;
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0.035px;
	}
}

.layout__tabs {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.TabTerminals {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding-right: 12px;
}

.TabTerminals__list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.TabTerminals__item {
	border-radius: 8px;
	border: 1px solid #9fc4ff;
	background: #fffbff;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;

	> div {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
	}
	> div,
	p {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	span {
		font-weight: 500;
	}

	button {
		cursor: pointer;
	}
}

.allClientsFilters {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.client__status p {
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.approved {
	color: #149527;
}

.awaiting {
	color: #ff8e16;
}

.rejected {
	color: #ba1a1a;
}

.selectContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	& p {
		color: #1c1c1e;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
	}
}
