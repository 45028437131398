.damage_area_select_block {
	display: flex;
	flex-direction: column;
	gap: 6px;
	p {
		color: #000;
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
	}
	p:last-child {
		text-align: end;
		margin-bottom: 8px;
	}
}

.checkboxMain {
	display: flex;
	margin-top: 28px;
	flex-direction: column;
	gap: 4px;
	> div {
		display: flex;
		gap: 12px;
		border-radius: 8px;
		background: #fffbff;
		align-items: flex-start;
		padding: 16px 8px;
	}
}

.selectBtns {
	display: flex;
	gap: 8px;
}
.checkBox_block {
	display: flex;
	flex-direction: column;
}
.checkBox_items {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
}
.checkBox_item {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 10px;
}
.options {
	display: flex;
	flex-direction: row;
	gap: 20px;
}
.options_item {
	display: flex;
	flex-direction: row;
	gap: 10px;
}
