.inputCalendar {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 6px;

	p {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
	}
}

.inputCalendarBlock {
	display: flex;
	gap: 5px;
}

.input_required {
	color: #ff8e16 !important;
	font-size: 16px !important;
	font-weight: 700 !important;
}
