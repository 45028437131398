.wrapper {
	// z-index: 9003;
	span {
		color: #1c1c1e;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
		margin-bottom: 6px;
	}
}
.title_block {
	display: flex;
	gap: 5px;
}
