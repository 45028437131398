.filtrBlock {
	display: flex;
	overflow: auto;
	flex-direction: column;
	padding: 16px;
	border-radius: 16px;
	background: #fff;
	width: 100%;
	height: 100%;
}

.title {
	color: #000;
	font-size: 16px;
	font-weight: 600;
	line-height: 26px; /* 162.5% */
	letter-spacing: 0.035px;
	margin-bottom: 16px;
}

.noManager {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 8px 0px;
	margin-bottom: 8px;
}

.filterChechsSwitcher {
	color: #1c1c1e;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px; /* 157.143% */
}

.importExportOrder {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 8px 0px;
	margin-bottom: 8px;
}

.inputSelects {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 20px;
	max-height: calc(100svh - 560px);
	overflow: auto;
	padding-right: 12px;
	padding-bottom: 8px;
}

.inputSelects::-webkit-scrollbar {
	width: 2px; /* ширина всей полосы прокрутки */
}

.inputSelects::-webkit-scrollbar-track {
	background: #ffede3; /* цвет зоны отслеживания */
	height: 90%;
	margin-bottom: 20px;
}

.inputSelects::-webkit-scrollbar-thumb {
	background-color: #ff8e16; /* цвет бегунка */
	border-radius: 4px; /* округлось бегунка */
	height: 48px;
	border: 3px solid orange;
}

.datePicker {
	display: flex;
	flex-direction: column;
	gap: 8px;
	z-index: 999;
}
.withDatePicker {
	overflow-x: visible;
	overflow-y: clip;
	max-height: calc(100% - 105px);
}
.withAdditionalInfo {
	max-height: calc(100% - 222px);
}

.datePickerSpan {
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.035px;
}

.selectContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	& p {
		color: #1c1c1e;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
	}
}
