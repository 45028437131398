.checkAct {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.carBlock {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.containerBlock {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
