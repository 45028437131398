.main_damage_card {
	border-radius: 8px;
	background: #fffbff;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 8px;
	overflow-x: auto;

	&::-webkit-scrollbar {
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 0;
	}
}

.title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;

	p {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
	}

	span {
		padding: 0 2px;
		color: #8e8e93;
		font-size: 16px;
		font-weight: 500;
		line-height: 22px;
	}
}

.types {
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;

	p {
		color: #1c1c1e;
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
	}
}

.damage_card_descr {
	color: #2c2c2e;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px; /* 157.143% */

	display: -webkit-box;
	-webkit-line-clamp: 3; /* Количество строк */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.images_container {
	display: flex;
	gap: 4px;
}

.damage_image {
	width: 48px;
	aspect-ratio: 1/1;
	border-radius: 4px;
}
.types_side_withIcon {
	display: flex;
	align-items: center;
	gap: 8px;
}
