.appContainer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	background: #fffbff;
	border-radius: 12px;
	padding: 12px;
}

.leftInfo {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.bottomInfo {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.infoContainer {
	display: flex;
	gap: 8px;
	align-items: center;

	> div {
		display: flex;
		align-items: baseline;
		gap: 4px;
	}
}

.title {
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
}

.info {
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.08px;
}

.subInfo {
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.035px;
}

.info__block {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.info__title {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 18px;
	}

	.info__arrow {
		transform: rotate(-90deg);
	}
}

.info__status {
	border-radius: 4px;
	padding: 0 4px;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.08px;
	min-height: 24px;
	display: flex;
	align-items: center;

	&.successful {
		background: rgba(20, 149, 39, 0.1);
		color: #149527;
	}

	&.warning {
		background: #ffede3;
		color: #ff8e16;
	}

	&.damagesLength {
		background: #f3eeff;
		color: #5c53a7;
		padding: 0 8px;
	}
}

.container__status {
	border-radius: 4px;
	padding: 0 4px;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.035px;

	&.warning {
		background: #ffede3;
		color: #ff8e16;
	}
}

.actionContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 82px;
}
