.dateCreateOrderTitle {
    color: #1C1C1E;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.035px;
}

.dateCreateOrder {
    display: flex;
    align-items: center;
    gap: 6px;
    > button {
        margin-top: 6px;
    }
}

.dateCreateOrderColumn {
    flex-direction: column !important;
    align-items: normal !important;
}

.columnTipeInputPicker {
    display: flex;
    align-items: center;
    gap: 4px;
    > button {
        margin-top: 26px;
    }
}