.block {
	border-radius: 12px;
	background: #fffbff;
	padding: 16px;
	gap: 20px;
	width: 100%;
	max-width: 360px;
	display: flex;
	flex-direction: column;
}

.block_demoDescr {
	text-align: center;
	font-family: 'AvenirNextCyr';
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.24px;
}

.block h5 {
	color: #000;
	font-family: 'AvenirNextCyr';
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0.24px;
	text-align: center;
}

.block__btns {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 16px;

	a,
	button {
		width: 100%;
	}
}

.navBar {
	width: 100%;
	padding: 4px 8px;
	max-width: 360px;

	button {
		background-color: #fffbff;
	}
}

.block__inps {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
