.addContainers {
	> form {
		border-radius: 16px;
		background: #fffbff;
		box-shadow:
			0px 8px 8px -4px rgba(24, 39, 75, 0.08),
			0px 4px 6px -4px rgba(24, 39, 75, 0.12);
		backdrop-filter: blur(6px);
		padding: 12px;
		justify-content: space-between;
		display: flex;
		height: auto;
		max-height: 100%;
		flex-direction: column;
		gap: 12px;
		height: 100%;
	}
}

.addContainers__header {
	color: #1c1c1e;
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	gap: 4px;
}

.topTitle_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.addContainers__title {
	color: #1c1c1e;
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
}

.typeSizeBlock {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 16px;
	> div {
		width: 50%;
	}
}

.addContainers__subtitle {
	color: #149527;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.addContainers__mainBlock {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// align-items: center;
}

.addContainers__body {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.addContainers__form {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.form__select {
	display: flex;
	flex-direction: column;
	gap: 12px;

	> label {
		// padding: 0 12px;
		color: #1c1c1e;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
	}

	> div {
		position: relative;
	}
}

.mobContBtn {
	border-radius: 8px;
	background: #5c53a7;
	padding: 14px 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-left: auto;
	gap: 12px;
	color: white;
}

.form__switch {
	padding: 6px 0px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	gap: 16px;

	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;
	}
	p {
		color: #1c1c1e;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
	}

	@media (max-width: 1100px) {
		gap: 16px;
		flex-direction: column;
		> div {
			justify-content: space-between;
		}
	}
}

.form__input {
	display: flex;
	flex-direction: column;
	gap: 12px;
	max-height: calc(100svh - 430px);
	overflow-y: auto;
	padding-right: 8px;
	// скролл
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}

	> div {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		width: 100%;
		gap: 12px;

		> div {
			width: 100%;
		}

		> button {
			margin-bottom: 6px;
		}
	}
	> label {
		color: #1c1c1e;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;

		&::before {
			content: '*';
			color: #ff8e16;
			margin-right: 4px;
		}
	}
}

.addContainers__footer {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 10px;
	justify-content: flex-end;
	align-items: center;
}

@media (max-width: 743.9px) {
	.typeSizeBlock {
		flex-direction: column;
	}
	.addContainers__form {
		max-height: calc(100svh - 448px);
		overflow: auto;
	}

	.addContainers__footer {
		justify-content: flex-end;
		gap: 8px;
	}

	.addContainers {
		> form {
			padding: 20px 12px 34px 12px !important;
			gap: 14px;
		}
	}
}
.container_number_wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
}
.container_number_icons_wrapper {
	display: flex;
	flex-direction: row;
	position: absolute;
	left: 255px;
	top: 2%;
	gap: 10px;
}
.container_number_input_wrapper {
	display: flex;
	height: -moz-fit-content;
	height: fit-content;
	align-items: flex-end;
	gap: 5px;
}
.container_number_input_main {
	width: 100%;
}
.container_number_input_button {
}

.details__unset {
	padding: 2px;
	// width: 16px;
	// height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	background: #8c8c8c;
}
.details__danger {
	padding: 2px;
	// width: 16px;
	// height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	background: #de3730;
}
.details__damage {
	padding: 2px;
	// width: 16px;
	// height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	background: #ff8e16;
}
.addContainers__toast {
	position: absolute;
	width: 100%;
	height: 100%;
}
