.switcher {
	position: relative;
	display: inline-block;
	width: 41px;
	height: 17px;
}

.switcher input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #b4c5ff;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 34px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 24px;
	width: 24px;
	left: -1px;
	bottom: -3px;
	background-color: #356ef4;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: #ffb77d;
}

input:checked + .slider:before {
	background-color: #ff8e16;
}

input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}
