@import '_variables';

@font-face {
	font-family: 'AvenirNextCyr';
	src: url('../fonts/AvenirNextCyr-Demi.ttf');
	src:
		local('AvenirNextCyr Demi'),
		local('AvenirNextCyr Demi'),
		url('../fonts/AvenirNextCyr-Demi.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AvenirNextCyr';
	src: url('../fonts/AvenirNextCyr-Regular.ttf');
	src:
		local('AvenirNextCyr Regular'),
		local('AvenirNextCyr Regular'),
		url('../fonts/AvenirNextCyr-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AvenirNextCyr';
	src: url('../fonts/AvenirNextCyr-Medium.ttf');
	src:
		local('AvenirNextCyr Medium'),
		local('AvenirNextCyr Medium'),
		url('../fonts/AvenirNextCyr-Medium.ttf') format('truetype');
	font-weight: 450;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Bold.ttf');
	src:
		local('Montserrat Bold'),
		local('Montserrat Bold'),
		url('../fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-SemiBold.ttf');
	src:
		local('Montserrat SemiBold'),
		local('Montserrat SemiBold'),
		url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Medium.ttf');
	src:
		local('Montserrat Medium'),
		local('Montserrat Medium'),
		url('../fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Regular.ttf');
	src:
		local('Montserrat Regular'),
		local('Montserrat Regular'),
		url('../fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Medium.ttf');
	src:
		local('Raleway Medium'),
		local('Raleway Medium'),
		url('../fonts/Raleway-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Regular.ttf');
	src:
		local('Raleway Regular'),
		local('Raleway Regular'),
		url('../fonts/Raleway-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-SemiBold.ttf');
	src:
		local('Raleway SemiBold'),
		local('Raleway SemiBold'),
		url('../fonts/Raleway-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Montserrat';
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	display: flex;
	color: #0040a7;
}

button,
input,
textarea {
	outline: none;
	border: none;
	background: none;
	font-family: 'Montserrat';
}

.backImg {
	background-image: linear-gradient(0deg, rgba(28, 28, 30, 0.32) 0%, rgba(28, 28, 30, 0.32) 100%), url('../images/mobile.webp');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vh;
	padding: 8px 16px;

	@media (min-width: 743.9px) {
		background-image: linear-gradient(0deg, rgba(28, 28, 30, 0.32) 0%, rgba(28, 28, 30, 0.32) 100%), url('../images/desktop.webp');
	}
}

.container-center {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: flex-end;
	min-height: calc(var(--vh, 1vh) * 100 - 0px);
}

@media (min-width: 744px) {
	.container-center {
		justify-content: center;
	}
}

.form .input:nth-child(2) {
	margin-top: 16px;
}

// @media (max-width: 743.9px) {
//   .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
//     overflow: visible;
//   }
// }

.infinite-scroll-component__outerdiv {
	width: 100%;
}

body {
	overflow: hidden;
}

[class*='MuiSelect'] {
	font-family: 'Montserrat' !important;
}
