.account__wrap {
	width: 100%;
}

.account__container {
	background: #101a28;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: calc(100% - 100px);
	padding: 8px 0 0 0;

	@media (min-width: 639.9px) {
		height: calc(100% - 52px);
		padding: 16px;
	}
}

.actSlider {
	padding-left: 8px;
	@media (min-width: 639.9px) {
		padding-left: 0px;
	}
}

.stack {
	height: calc(100vh - 144px);
}
