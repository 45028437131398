.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10000;
}

.fullScreen {
	width: 100%;
	height: 100%;
	max-width: none;
	max-height: none;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.modalContainerEnd {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 8px;

	> button {
		background-color: #ffffff;
	}
}

.container {
	width: 100%;
}

.modalWrap {
	height: 100%;
}

.modalContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;

	&.backContainers {
		background-image: url('../../assets/images/backContainers.png');
	}

	&.backOrders {
		background-image: url('../../assets/images/backOrders.png');
	}

	&.backAnalytic {
		background-image: url('../../assets/images/backAnalytic.png');
	}
}

.modal__content {
	max-width: 400px;
	background-color: rgb(255, 255, 255);
	padding: 20px;
	border-radius: 12px;
}

.modal__form {
	display: flex;
	flex-direction: column;
	gap: 12px;

	h3 {
		font-size: 18px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0.07999999821186066px;
	}

	> p {
		font-size: 16px;
		line-height: 20px;
	}
}

.modal__input {
	display: flex;
	flex-direction: column;
	gap: 4px;

	label {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		padding-left: 10px;
		letter-spacing: 0.035px;
	}

	input {
		background-color: #e4dfff;
		border: 1px solid #443a8e;
		border-radius: 12px;
		color: #2d2276;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
		padding: 8px;
	}
}
.modal__btn {
	display: flex;
	flex-direction: column;
	gap: 4px;

	p {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
	}
}

.modal__privacy {
	display: flex;
	flex-direction: row;
	gap: 8px;
	margin-bottom: 12px;
	align-items: center;
}
