.allOrders_info {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 12px;
	border-radius: 16px;
	width: max-content;
	border: 2px solid var(--core-primary-80, #9fc4ff);
	background: var(--core-primary-99, #fffbff);
}

.topLine {
	display: flex;
	justify-content: space-between;
	gap: 80px;
	p {
		color: #000;
		min-width: 120px;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.108px;
	}
}

.btnInfo {
	border-radius: 6px;
	background: #f3eeff;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	max-width: 248px;
	padding: 4px;
	color: #1c1c1e;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0.5px;
}

.btnInfo_arrowTop {
	> div {
		transform: rotate(180deg);
	}
}

.additionalInfo {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	> div:first-child p {
		display: flex;
		flex-direction: column;
		gap: 4px;
		color: #000;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.108px;
	}
}
