.dataClient {
  display: flex;
  padding: 12px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid #5C53A7;
  background: #FFFBFF;
  max-width: 50%;
}

.dataClient__container {
  max-width: 100%;
  // background: #263141;  
  @media (min-width: 639.9px) {
    max-height: calc(100vh - 183px);
  }
}

.dataClient__info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.dataClient__title {
  color: #1C1C1E;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.dataClient__info__list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    color: #1C1C1E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.dataClient__body {
  display: flex;
  overflow: auto;
  padding-right: 12px;
  max-height: calc(100svh - 566px);
  flex-direction: column;
  // скролл
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ffdebc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff8e16;
    border-radius: 4px;
  }
}

.dataClient__item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dataClient__item__title {
  color: #1C1C1E;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}

.dataClient__slide {
  height: 100% !important;
  padding: 0 0 25px 0 !important;
  max-width: 100% !important;
  width: 100% !important;
  border-radius: 0 !important;
  background: transparent !important;
  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important;
  cursor: pointer;
  transition: 0.3s;
}

.dataClient__footer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1279.9px) {
  .dataClient {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 24px;
    padding: 24px 12px;
    gap: 20px;
    max-height: calc(100svh - 208px);
    background: #263141;
    overflow: auto;
    > div:last-child {
      position: fixed;
      bottom: 56px;
      z-index: 2;
      width: 100%;
      left: 0;
      padding: 12px;
      background: #263141;
    }
  }

  .dataClient__container {
    padding: 0px 0px 62px 0px;
  }
}

@media (max-width: 639.9px) {
  .dataClient {
    > div:last-child {
      button {
        width: 100%;
      }
    }
  }
}
