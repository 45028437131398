.choiceDamage_main_block {
	display: flex;
	flex-direction: column;
	gap: 8px;
	> button {
		width: 100%;
	}
}
.container_sides {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.moreBtns {
	// padding-right: 12px;
	> button > span > div {
		transform: rotate(180deg);
	}
}
.containerSide_button {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	font-size: 16px;
	padding: 10px 12px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.08px;
	border-radius: 10px;
	max-height: 44px;
	background-color: transparent;
	border: 1px solid #5c53a7;
	color: #5c53a7;
}
.containerSide_button_disabled {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	font-size: 16px;
	padding: 10px 12px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.08px;
	border-radius: 10px;
	max-height: 44px;
	background-color: transparent;
	border: 1px solid #8e8e93 !important;
	color: #8e8e93 !important;
	cursor: not-allowed;
}
.containerSide_button:hover {
	color: #443a8e;
	border: 1px solid #443a8e;
}
.containerSide_button_count {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	background-color: #e4dfff;
}
.containerSide_button_emptyCount {
	display: block;

	width: 24px;
	height: 24px;
}
