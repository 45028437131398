.swiper {
	width: 100%;
	height: 100%;
}
.swiper_wrapper {
	background-color: #ebebeb;
	padding: 16px;
	overflow-x: hidden;
}
.swiper_wrapper .swiper-slide {
	display: flex;
	width: 160px;
	min-width: 160px;
	max-width: 160px;
	padding: 8px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	height: fit-content;
	border-radius: 12px;
	background: rgba(255, 251, 255, 0.96);
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		background: #76aaff;
		color: #fffbff;

		.sliderForTerminals_capacity {
			color: #fffbff;
		}
		.IconContainerCarSVG path {
			fill: #fffbff;
		}
		.sliderForTerminals_name {
			color: #fffbff;
		}
	}

	&.active {
		background: #0053da;
		color: #fffbff;

		.sliderForTerminals_capacity {
			color: #fffbff;
		}
		.sliderForTerminals_name {
			color: #fffbff;
		}
	}
}
.swiper-slide.selected {
	background: #0053da;

	span {
		color: #fffbff;
	}
}

.sliderForTerminals_wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: row;
}

.sliderForTerminals_description {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;
}
.sliderForTerminals_name {
	color: var(--core-dark-10, #1c1c1e);
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px; /* 128.571% */
	letter-spacing: 0.08px;
}
.sliderForTerminals_capacity {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	color: var(--core-dark-20, #2c2c2e);
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
	letter-spacing: 0.08px;
}

.sliderForTerminals_notifications {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
}
.sliderForTerminals_notifications_positive {
	display: flex;
	width: 20px;
	height: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 50%;
	background: var(--core-state-successful-40, #149527);

	color: #fff;

	text-align: center;
	font-family: Montserrat;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 10px; /* 100% */
	letter-spacing: 0.08px;
}
.sliderForTerminals_notifications_negative {
	display: flex;
	width: 20px;
	height: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 50%;
	background: var(--core-state-error-40, #ba1a1a);

	color: #fff;

	text-align: center;
	font-family: Montserrat;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 10px; /* 100% */
	letter-spacing: 0.08px;
}
