.content__users {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
	align-content: flex-start;
	padding-right: 8px;
	overflow-x: hidden !important;
	width: 100%;
	// height: 100%;
	overflow-y: scroll;

	@media (min-width: 743.9px) {
		overflow: scroll;
	}

	@media (min-width: 1023.9px) {
		width: 100%;
		min-width: 354px;
	}
}

.content__users_inSideBar {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 80%;
	gap: 8px;
	align-content: flex-start;
	padding-right: 8px;
	overflow-x: hidden !important;
	// width: calc(100% - 475px);
	overflow-y: scroll;

	@media (min-width: 743.9px) {
		overflow: scroll;
	}

	@media (min-width: 1023.9px) {
		width: 100%;
		min-width: 354px;
	}
}
.content__users_withSideBar {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
	align-content: flex-start;
	padding-right: 8px;
	overflow-x: hidden !important;
	overflow-y: scroll;

	@media (min-width: 743.9px) {
		overflow: scroll;
	}

	@media (min-width: 1023.9px) {
		width: 100%;
		min-width: 100%;
	}
}
.content__users::-webkit-scrollbar {
	width: 2px;
}

.content__users::-webkit-scrollbar-track {
	background-color: #e4dfff;
	border-radius: 4px;
}

.content__users::-webkit-scrollbar-thumb {
	background-color: #8f86de;
	border-radius: 4px;
}

.content__user {
	position: relative;
	color: #000;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	border-radius: 16px;
	background: #fff;
	padding: 4px;
	transition:
		background 0.3s,
		width 0.5s;
	min-width: 100%;
	height: inherit;
	height: 56px;
	cursor: pointer;
	border-radius: 16px;
	transition: 0.2s ease-in-out;
	border: 2px solid var(--core-primary-80, #9fc4ff);

	&::before {
		content: '';
		position: absolute;
		height: 30px;
		left: -1px;
		// top: 20px;
		border: 2px solid;
		border-color: #9fc4ff;
		transition: 0.2s ease-in-out;
		border-radius: 0px 8px 8px 0px;
	}

	&.show {
		// min-width: 100%;
		// background: #5c53a7;
		// width: calc(100% - 475px);
		width: 100%;
		border: 2px solid #ff8e16;
		transition: 0.2s ease-in-out;
		// color: #fffbff;
		&::before {
			border-color: #ff8e16 !important;
		}
	}

	&.show p {
		// color: #fffbff;
	}

	&.w_100 {
		width: 100%;
		transition: 0.2s ease-in-out;
	}

	&.with_sidebar {
		width: 100%;
		// width: calc(100% - 475px) !important;
		transition: 0.2s ease-in-out;
	}
	&.newList_forTerminal {
		min-width: calc(50% - 4px);
		width: calc(50% - 4px);
	}

	@media (min-width: 1279.9px) {
		width: min-content;
		min-width: calc(33% - 4px);
	}
}

.content__user:hover {
	border: 2px solid var(--core-primary-80, #5c53a7);
	&::before {
		content: '';
		position: absolute;
		height: 30px;
		left: -1px;
		// top: 20px;
		border: 2px solid;
		border-color: #5c53a7;
		transition: 0.2s ease-in-out;
		border-radius: 0px 8px 8px 0px;
	}
	&.show {
		// min-width: 100%;
		// background: #5c53a7;
		// width: calc(100% - 475px);
		width: 100%;
		border: 2px solid #ff8e16;
		transition: 0.2s ease-in-out;
		// color: #fffbff;
		&::before {
			border-color: #ff8e16 !important;
		}
	}
}
.content__user:active {
	border: 2px solid var(--core-primary-80, #443a8e);
	&::before {
		content: '';
		position: absolute;
		height: 30px;
		left: -1px;
		// top: 20px;
		border: 2px solid;
		border-color: #5c53a7;
		transition: 0.2s ease-in-out;
		border-radius: 0px 8px 8px 0px;
	}
	&.show {
		// min-width: 100%;
		// background: #5c53a7;
		width: 100%;
		border: 2px solid #ff8e16;
		transition: 0.2s ease-in-out;
		// color: #fffbff;
		&::before {
			border-color: #ff8e16 !important;
		}
	}
}
.user__img {
	border-radius: 12px;
	min-width: 48px;
	height: 48px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
}

.user__name {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;

	span {
		font-size: 14px;
		font-weight: 500;
	}

	p {
		font-size: 14px;
		font-weight: 400;
	}
}

.approved {
	color: #149527;
}

.awaiting {
	color: #ff8e16;
}

.rejected {
	color: #ba1a1a;
}
