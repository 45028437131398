.block_bg {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 8px;
    border-radius: 24px;
    background: rgba(255, 251, 255, 0.96);
}

.top_title_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    p {
        color: #1C1C1E;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px; /* 144.444% */
    }
}

.search_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.terminal_list {
    display: flex;
    flex-wrap: wrap;
    padding-right: 8px;
    gap: 8px;
    justify-content: flex-start;
    overflow-y: auto;
    // скролл
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #ffdebc;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ff8e16;
        border-radius: 4px;
    }
}