// CarsForm
.carsForm_layout {
  display: flex;
  padding: 12px;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 24px;
  background: #fffbff;
  box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
    0px 8px 18px -6px rgba(24, 39, 75, 0.12);
}
.carsForm__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.carsForm__header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.carsForm__header_text {
  color: #1c1c1e;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.carsForm__header_bottom > span {
  color: #1c1c1e;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.carsForm__formBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.carsForm__formBlock > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.carsForm__formBlock_top {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.carsForm__formBlock_top > .input > .InputTopStr {
  display: flex;
  flex-direction: row;
  color: red;
}
.carsForm__formBlock_bottom {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
// CarsList
.carsList__layout {
  display: flex;
  padding: 12px;
  // max-height: 70vh;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 24px;
  background: rgba(255, 251, 255, 0.96);
  box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
    0px 8px 18px -6px rgba(24, 39, 75, 0.12);
}
.carList__header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.carList__header_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.carList__header_bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.carList__header_bottom_switch {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.carList__header_text {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
}

.carList__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.carList__list_overflow {
  display: flex;
  // max-height: calc(100svh - 380px);
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  gap: 12px;
  // border-radius: 0 0 24px 24px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}
.carsForm_block {
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.carList__list_overflow::-webkit-scrollbar {
  width: 2px; /* ширина всей полосы прокрутки */
}

.carList__list_overflow::-webkit-scrollbar-track {
  background: #ffede3; /* цвет зоны отслеживания */
  height: 90%;
  margin-bottom: 20px;
}

.carList__list_overflow::-webkit-scrollbar-thumb {
  background-color: #ff8e16; /* цвет бегунка */
  border-radius: 4px; /* округлось бегунка */
  height: 48px;
  border: 3px solid orange;
}
.carList__add_btn {
  width: 36px;
  height: 36px;
  display: flex;
  // padding: 6px;
  align-items: center;
  // gap: 8px;
  border: 1px solid #5c53a7;
}
.dataClient__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.noCarsForm_block {
  display: contents;
}

.mobContBtn {
  border-radius: 8px;
  background: #5c53a7;
  padding: 14px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  color: white;
}

.step3Btns {
  background: rgba(38, 49, 65, 0.96);
  padding: 8px 8px 32px 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .mobContBtn {
    max-width: 88px;
  }
}

@media (max-width: 1279.9px) {
  .carsList__layout {
    position: absolute;
    left: 0;
    bottom: 38px;
    width: 100% !important;
    height: calc(100vh - 200px);
  }
  .carList__list_overflow {
    height: calc(100vh - 400px);
  }

  .carsForm_layout {
    // position: absolute;
    left: 0;
    bottom: 38px;
    width: 100% !important;
    height: calc(100vh - 240px);
    box-shadow: none;
  }
}

//Switcher
// .form__switch {
//     padding: 6px 0 6px 12px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;

//     > div {
//       display: flex;
//       flex-direction: row;
//       gap: 16px;
//     }
//     p {
//       color: #1c1c1e;
//       font-size: 16px;
//       font-weight: 500;
//       line-height: 20px;
//       letter-spacing: 0.035px;
//     }
//   }

//   .form__input {
//     display: flex;
//     flex-direction: column;
//     gap: 12px;

//     > div {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       width: 100%;
//       gap: 12px;

//       > div {
//         width: 100%;
//       }
//     }
//     > label {
//       color: #1c1c1e;
//       font-size: 16px;
//       font-weight: 500;
//       line-height: 20px;
//       letter-spacing: 0.035px;

//       &::before {
//         content: "*";
//         color: #ff8e16;
//         margin-right: 4px;
//       }
//     }
//   }
