.modalContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.modTitle {
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.035px;
	text-align: center;
}
