.cardAuto {
	border: 2px solid #9fc4ff;
	background: #fffbff;
	backdrop-filter: blur(6px);
	display: flex;
	flex-direction: column;
	cursor: pointer;
	justify-content: center;
	max-height: 130px;
	position: relative;
	padding: 8px 16px;
	gap: 14px;
	border-radius: 12px;

	//TODO: Отладить размер в случае переноса
	width: 100%;

	&::before {
		content: '';
		position: absolute;
		height: 36px;
		left: -1px;
		top: 12px;
		border: 2px solid;
		border-color: #9fc4ff;
		border-radius: 0px 8px 8px 0px;
	}
}

.error_btn {
	position: absolute;
	left: -16px;
}

.padding_left {
	padding-left: 16px;
}

.successful_btn {
	position: absolute;
	right: -16px;
}

.mainBlock {
	display: flex;
	width: 100%;
	gap: 4px;
}

.firstBtns {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.selectedContainerCard {
	background: #443a8e;
	p {
		color: #fffbff;
	}
	&::before {
		border-color: #0053da !important;
	}
}

.activeCardAuto {
	border: 2px solid #ff8e16 !important;
	&::before {
		border-color: #ff8e16 !important;
	}
}

.cardAuto__row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	p {
		gap: 12px;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.08px;
	}
}

.cardAuto__name {
	font-size: 14px !important;
	font-weight: 500 !important;
	line-height: 18px !important;
	letter-spacing: 0.08px !important;
}

.carContainers {
	display: flex;
	gap: 4px;
	padding: 4px 8px 4px 4px;
	border-radius: 8px;
	align-items: center;
	background: #fffbff;
	p {
		color: #443a8e;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.08px;
	}
}

.dispNone {
	display: none;
}
