.search__block {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 4px;
	padding: 2px 2px 2px 4px;
	border-radius: 8px;
	background: #0053da;

	input {
		border-radius: 8px;
		background: #fffbff;
		padding: 6px 12px;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		width: 100%;
	}

	input::placeholder {
		opacity: 1;
		color: #636366;
	}

	&:hover {
		cursor: pointer;
		background: #014cc6;
	}

	&.active {
		background: #0040a7;
	}

	button {
		background: #0040a7;
	}
}
