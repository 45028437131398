// скролл внутри таблицы
.table__container {
	max-width: calc(100vw - 16px);
	@media (min-width: 1279.9px) {
		display: inline-grid;
		max-width: 100%;
		width: 100%;
	}
}
.table__container > div {
	> div:nth-child(2)::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	> div:nth-child(2)::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 0;
	}

	> div:nth-child(2)::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 0;
	}
}

// шапка таблицы
thead tr {
	th {
		color: #fffbff !important;
		font-size: 14px !important;
		font-weight: 600 !important;
		line-height: 22px !important;
		border-bottom: 1px solid #e4dfff !important;
		padding: 4px 10px 4px 12px !important;

		div {
			color: #fffbff !important;

			div:nth-child(3) {
				margin-right: 0 !important;
				right: -5px !important;
				padding: 0 !important;

				hr {
					transform: scale(1.2) translateX(4px) !important;
					border-color: #8f86de !important;
					border-width: 1px !important;
					height: 18px !important;
				}
			}

			.MuiInputAdornment-root {
				width: 40px;
			}
		}

		button {
			color: #fffbff !important;
			opacity: 1 !important;
			padding: 0 !important;
		}
	}

	th:nth-child(1) {
		min-width: 48px !important;
		// width: 48px !important;
		max-width: 48px !important;
		padding: 0 !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		text-align: center !important;

		> div {
			justify-content: center !important;
		}

		span {
			color: #ffffff3d !important;
		}
		svg {
			fill: #ffffff !important;
		}
	}

	th:nth-child(2) {
		border-right: 1px solid #e4dfff;
		padding: 0 !important;
		min-width: 48px !important;
		max-width: 48px !important;

		div {
			display: none;
		}
	}
}

// ячейки таблицы
td {
	border-right: 1px solid #e4dfff;
	border-bottom: 1px solid #e4dfff !important;
	padding: 8px 8px 8px 12px !important;
	font-family: 'Montserrat' !important;
	height: 36px !important;
	font-size: 14px !important;
	line-height: 14px !important;

	input {
		font-family: 'Montserrat' !important;
	}
}

td:first-child {
	text-align: center;
	padding: 4px 0 !important;
	min-width: 48px !important;
	max-width: 48px !important;

	span {
		margin: 0 auto !important;
	}

	svg {
		path {
			color: #274b7e !important;
		}
	}

	.MuiTypography-root {
		color: #fffbff !important;
	}
}

td:nth-child(2) {
	width: 48px !important;
	min-width: 48px !important;
	max-width: 48px !important;
	padding: 0 !important;
	display: flex !important;
	justify-content: center !important;

	button {
		width: 28px !important;
		height: 28px !important;
		padding: 4px !important;
		border-radius: 4px !important;
	}
}

tbody tr td:only-child {
	text-align: center;
	padding: 4px 0 !important;
	min-width: 100% !important;
	max-width: 100% !important;

	.MuiTypography-root {
		color: #ffffff !important;
		font-family: 'AvenirNextCyr' !important;
		font-style: normal !important;
	}
}

// футер
.table__footer {
	width: 100%;
	justify-content: space-between;
	color: #ffffff;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	gap: 12px;
	min-height: 52px;

	@media (max-width: 550px) {
		justify-content: flex-start;
		align-items: flex-start;

		div {
			font-size: 12px;
		}
	}
}

.table__footer_btns {
	display: flex;
	align-items: center;
	gap: 12px;
}

.table__footer_preloader {
	display: flex;
	align-items: center;
	gap: 12px;
}

// модальное окно редактирования одного контейнера
@media (max-width: 639.9px) {
	.MuiDialog-container.MuiDialog-scrollPaper {
		align-items: flex-end !important;
	}
}

.MuiDialog-paper {
	background-color: transparent !important;
	max-width: 500px !important;
	box-shadow: none !important;
	margin: 0 !important;

	@media (max-width: 639.9px) {
		max-width: 100% !important;
		width: 100% !important;
	}

	.MuiPickersLayout-root:first-child {
		background-color: #fffbff !important;
		border-radius: 18px;
	}
}

.modalEdit {
	width: 100%;
	background-color: #fffbff;
	border-radius: 16px;
}

.modalEdit__header {
	padding: 16px 24px 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalEdit__header_info {
	display: flex;
	flex-direction: column;
	gap: 8px;

	p {
		color: #1c1c1e;
		font-family: 'AvenirNextCyr';
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		letter-spacing: 0.035px;
	}

	span {
		color: #1c1c1e;
		font-family: 'AvenirNextCyr';
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.035px;
	}
}

.modalEdit__body {
	padding: 16px 12px 8px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-height: calc(100vh - 300px);
	overflow-y: auto;

	> form {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
}

.modalEdit__body::-webkit-scrollbar {
	width: 4px;
}

.modalEdit__body_inputs {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
}

// модальное окно инпуты
.modalEditСontainers {
	width: 100%;
	.modalEdit__body_inputs > div {
		width: calc(50% - 8px) !important;
		position: relative;

		@media (max-width: 639.9px) {
			width: 100% !important;
		}
	}

	.modalEdit__body_inputs > div:last-child {
		width: 100% !important;
	}
}

.modalEdit__body_inputs > div > label {
	color: #1c1c1e !important;
	font-family: Montserrat !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 20px !important;
	padding-left: 10px !important;
	letter-spacing: 0.035px !important;
	position: relative !important;
	transform: translate(0) scale(1) !important;

	span {
		color: #ff8e16 !important;
		position: absolute !important;
		left: 0 !important;
	}
}

.modalEdit__body_inputs > div > div:before,
.modalEdit__body_inputs > div > div:after {
	border: none !important;
}

.modalEdit__body_inputs {
	> div {
		color: #2d2276 !important;
		font-family: 'Montserrat' !important;
		font-size: 16px !important;
		font-weight: 500 !important;
		line-height: 20px !important;
		letter-spacing: 0.035px !important;
		display: flex !important;
		flex-direction: column !important;
		gap: 6px !important;
	}

	input {
		color: #2d2276 !important;
		font-family: 'Montserrat' !important;
		font-size: 16px !important;
		font-weight: 500 !important;
		line-height: 20px !important;
		letter-spacing: 0.035px !important;
	}
}

.modalEdit__body_inputs input:focus {
	background-color: transparent !important;
}

.modalEdit__body_inputs > div > div > input ~ svg {
	right: 6px !important;
	color: #8f86de !important;
}

.modalEdit__body_inputs > div > div > div.MuiSelect-select {
	padding: 8px 14px !important;
	box-sizing: border-box !important;
	color: #2d2276;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.035px;
}

.modalEdit__body_inputs > div > div > input {
	padding: 8px 14px !important;
	box-sizing: border-box !important;
	height: auto !important;
}

.modalEdit__body_inputs > div > div > div:focus {
	background-color: transparent !important;
}

.modalEdit__body_inputs label ~ div {
	margin: 0 !important;
	background-color: #dfefff !important;
	border: 1px solid #0040a7 !important;
	border-radius: 12px !important;
}

.modalEdit__body_inputs label ~ p.Mui-error {
	position: absolute !important;
	margin: 0 0 0 10px !important;
	background-color: #fffbff !important;
	font-family: Montserrat !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 20px !important;
	letter-spacing: 0.035px !important;
}

// модальное окно переключатели
.modalEdit__body_switchers {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
	width: 100% !important;
	flex-wrap: wrap;

	.switchers__item {
		color: #1c1c1e;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	@media (max-width: 639.9px) {
		flex-direction: column;
	}
}

// модальное окно заявки
.modalEdit__body_orders {
	border-radius: 20px;
	background: var(--core-surfaces-dark-10-2, rgba(16, 28, 43, 0.92));
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	h6 {
		color: #fffbff;
		font-family: Montserrat;
		font-size: 18px;
		font-weight: 600;
		line-height: 22px;
		letter-spacing: 0.035px;
	}
}

.orders__info {
	display: flex;
	flex-direction: column;
	gap: 4px;

	p {
		color: #fffbff;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
		display: flex;
		gap: 4px;
		align-items: center;
	}

	span {
		color: #f3eeff;
		font-family: 'AvenirNextCyr';
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.035px;
	}
}

// модальное окно клиент
.modalEdit__body_clients {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;

	label {
		color: #1c1c1e;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		padding-left: 12px;
		letter-spacing: 0.035px;
	}
}

.clients__input {
	padding: 9px 14px;
	background-color: #e4dfff;
	border: 1px solid #443a8e;
	border-radius: 12px;
	color: #2d2276;
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.035px;
	position: relative;
}

.clients__input::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 14px;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 5px;
	border-color: #8f86de transparent transparent transparent;
}

// модальное окно футер
.modalEdit__footer {
	padding: 12px 16px;

	> div {
		display: flex;
		justify-content: space-between;
	}
	button {
		color: #fffbff !important;
		background-color: #ba1a1a !important;
		border-radius: 8px !important;
		padding: 8px 10px !important;
		text-transform: initial !important;
		font-family: 'Montserrat' !important;
		font-size: 14px !important;
		font-weight: 500 !important;
		line-height: 20px !important;
		letter-spacing: 0.08px !important;
		box-shadow: none !important;
		min-width: auto !important;
	}

	button:nth-child(2) {
		background-color: #149527 !important;
	}

	@media (max-width: 639.9px) {
		position: sticky;
		background-color: #ffffff;
		bottom: 0;
	}
}

// модальное окно редактирования множества
.modalEditСontainers,
.modalEditEntries {
	> div button {
		background-color: #fffbff;
	}
}
.modalClients__wrapper,
.modalEditСontainers__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10002;
	position: fixed;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	background: rgba(30, 30, 30, 0.56);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> div button {
		background-color: #fffbff;
	}
}

.modalClients__wrapper {
	z-index: 10005;
}

.modalEditСontainers__container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.modalEditСontainers__header {
	display: flex;
	flex-direction: column;
	gap: 12px;
	color: #1c1c1e;
	font-family: 'AvenirNextCyr';
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.035px;
	padding: 16px 24px;

	div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4px 12px;
	}

	p {
		color: #1c1c1e;
		font-family: 'AvenirNextCyr';
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		letter-spacing: 0.035px;
	}

	@media (max-width: 639.9px) {
		padding: 8px 12px;

		div {
			grid-template-columns: auto;
		}
	}
}

.modalEditСontainers__selectContainers {
	max-height: 80px;
	overflow-y: auto;
}

.modalEditСontainers__selectContainers::-webkit-scrollbar {
	width: 2px;
}

.modalEditСontainers__selectContainers::-webkit-scrollbar-track {
	background-color: #e4dfff;
	border-radius: 4px;
}

.modalEditСontainers__selectContainers::-webkit-scrollbar-thumb {
	background-color: #8f86de;
	border-radius: 4px;
}

.modalEditСontainers__body {
	padding: 0 12px 8px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-height: calc(100vh - 300px);
	overflow-y: auto;

	form {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		gap: 12px;

		> div {
			position: relative;
			width: 100%;
		}

		> div:nth-child(1),
		> div:nth-child(2),
		> div:nth-child(3),
		> div:nth-child(4) {
			width: calc(50% - 8px);
		}

		.w-100 {
			width: 100% !important;
		}
	}

	@media (max-width: 639.9px) {
		form {
			flex-direction: column;

			> div:nth-child(1),
			> div:nth-child(2),
			> div:nth-child(3),
			> div:nth-child(4) {
				width: 100%;
			}
		}
	}
}

.modalEditСontainers__footer {
	width: 100%;
	position: sticky;
	bottom: 0;
	background-color: #fff;
	padding: 12px 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-radius: 0 0 16px 16px;
}

.footer__btnsChanges {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
}

.footer__btns {
	display: flex;
	gap: 12px;

	button:nth-child(2) {
		width: 100%;
	}
}

// инпуты в модалке редактирования множества контейнеров
.editSelect {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.editSelect__label {
	padding-left: 10px;
	color: #1c1c1e;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.035px;
	position: relative;
	transition: 0.3s ease;

	&.required {
		&::before {
			content: '*';
			color: #ff8e16;
			position: absolute;
			left: 2px;
		}
	}

	&.error {
		color: #ba1a1a;
		transition: 0.3s ease;
	}
}

.editSelect__container {
	background-color: #e4dfff;
	border: 1px solid #443a8e;
	border-radius: 12px;
	padding: 9px 14px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	transition: 0.3s ease;

	input {
		color: #2d2276;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.035px;
	}

	.arrow {
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0;
		border-color: #8f86de transparent transparent transparent;
	}
}

// списки в модалке редактирования множества контейнеров
.selectLaptop {
	position: absolute;
	top: 66px;
	max-height: 205px;
	z-index: 1000;
	overflow: auto;
	background: #38557d;
	width: 100%;
	border-radius: 8px 16px 16px 16px;
	transition: 0.3s ease;
}

.selectLaptop::-webkit-scrollbar {
	width: 2px;
}

.modalEdit__body::-webkit-scrollbar-track,
.selectLaptop::-webkit-scrollbar-track {
	background-color: #e4dfff;
	border-radius: 4px;
}

.modalEdit__body::-webkit-scrollbar-thumb,
.selectLaptop::-webkit-scrollbar-thumb {
	background-color: #8f86de;
	border-radius: 4px;
}

.optionSelectLaptop {
	display: flex;
	overflow: auto;
	flex-direction: column;
	align-items: center;
	padding: 8px 0px;
}

.optionLaptop {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 6px 16px;
	border-radius: 0;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: rgba(25, 118, 210, 0.08);

		.optionText {
			color: #fffbff;
		}
	}

	&:last-child {
		border-radius: 0px 0px 12px 12px;
	}
}

.optionText {
	color: #fffbff;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

.selectedOption {
	background-color: #1a3355;

	.optionText {
		color: #fffbff;
	}
}

//кнопки перетаскивания в модалке скрыть и показать
.css-1pc03ml {
	button,
	.css-jtvzfs {
		display: none;
	}
}

// модалка скрыть и показать
.MuiSwitch-thumb {
	background-color: #356ef4 !important;
}

.MuiSwitch-track {
	background-color: #b4c5ff !important;
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
	border-radius: 8px !important;
	background: #38557d !important;
	box-shadow:
		0px 12px 42px -4px rgba(24, 39, 75, 0.12),
		0px 8px 18px -6px rgba(24, 39, 75, 0.12) !important;
	backdrop-filter: blur(6px) !important;
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar {
	width: 2px !important;
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar-track {
	background-color: #e4dfff !important;
	border-radius: 4px !important;
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar-thumb {
	background-color: #8f86de !important;
	border-radius: 4px !important;
}

ul.MuiMenu-list {
	background-color: #38557d;

	li {
		padding: 4px 12px !important;
	}

	li,
	li > div,
	li > div label span {
		font-family: 'Montserrat' !important;
		color: #fffbff !important;
		font-size: 14px !important;
		font-weight: 400 !important;
		line-height: 24px !important;
	}

	li > div div i {
		color: #fffbff !important;
	}

	> div {
		button {
			text-transform: initial !important;
			font-family: 'Montserrat' !important;
			color: #fffbff !important;
			border-radius: 8px !important;
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
		}

		button:nth-child(2) {
			display: none !important;
		}
	}
}

// модальное окно выбора клиентов
.modalClients__body {
	padding: 0 8px;
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (max-width: 429.9px) {
		padding: 0;
	}
}

.modalClients__body_search {
	padding: 12px 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	@media (max-width: 429.9px) {
		padding: 0 4px 12px 4px;
	}
}

.clients__list {
	display: flex;
	flex-direction: column;
	gap: 4px;
	height: calc(100vh - 320px);
	max-height: 548px;
	overflow-x: hidden;
	overflow-y: auto;
}

.clients__list::-webkit-scrollbar {
	width: 2px;
}

.clients__list::-webkit-scrollbar-track {
	background-color: #ffdebc;
	border-radius: 4px;
}

.clients__list::-webkit-scrollbar-thumb {
	background-color: #ff8e16;
	border-radius: 4px;
}

// карточка клиента
.itemClient {
	border-radius: 16px;
	border: 2px solid #9fc4ff;
	background: #fffbff;
	box-shadow:
		0px 12px 42px -4px rgba(24, 39, 75, 0.12),
		0px 8px 18px -6px rgba(24, 39, 75, 0.12);
	backdrop-filter: blur(6px);
	padding: 3px 12px 3px 6px;
	display: flex;
	gap: 12px;
	position: relative;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		height: 34px;
		left: -1px;
		top: 12px;
		border: 2px solid;
		border-color: #9fc4ff;
		border-radius: 0px 8px 8px 0px;
	}

	&.active {
		border: 2px solid #ff8e16;

		&::before {
			border-color: #ff8e16;
		}
	}
}

.itemClient__img {
	border-radius: 12px;
	min-width: 56px;
	height: 56px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
}

.itemClient__info {
	color: #1c1c1e;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.itemClient__info_name {
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.08px;
}

.itemClient__info_contract {
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.08px;
}

.table__preloader {
	position: absolute;
}

.modalEdit__body_inputs div.timeInput {
	display: flex !important;
	flex-direction: row !important;
	align-items: flex-end;
	width: 100%;

	> div:first-child {
		width: 100%;
	}

	[class*='TimeDatePicker_time__'] {
		left: -292px;
		top: -266px;
	}
}

.tableContainers {
	thead tr {
		th:nth-child(2) {
			display: none !important;
		}
	}

	td:nth-child(2) {
		display: none !important;
	}
}

.modal__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10002;
	position: fixed;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	background: rgba(30, 30, 30, 0.56);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.modalEditСontainers__stack {
	height: 100%;
	width: calc(100% - 16px);
	display: flex;
	max-width: 500px;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 639.9px) {
		width: 100%;
		max-width: 100%;
		justify-content: flex-end;
	}
}

.modalEditСontainers__block {
	display: flex;
	align-items: flex-end;
	flex-direction: column;

	> div:first-child {
		padding: 4px 16px;
	}
}
